import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { AuthenticationService } from '../_services/authentication.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})

export class DashboardComponent implements OnInit {
  isSideBar: boolean = false;
  getUserData: any = [];
  private updateSubscription: Subscription;
  constructor(
    private authenticationService: AuthenticationService
  ) {
    this.updateSubscription = this.authenticationService.update$.subscribe(() => {
      this.userDataget();
    });
  }

  ngOnInit(): void {
    this.userDataget();
  }

  onDataReceived(data: boolean) {
    this.isSideBar = data;
  }

  ngOnDestroy() {
    this.updateSubscription.unsubscribe();
  }

  userDataget() {
    this.authenticationService.GetUserDetail().subscribe((data: any) => {
      this.getUserData = data.data
    }, error => {
      console.log(error)
    });
  }
}
