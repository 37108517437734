import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.css']
})

export class ReportingComponent implements OnInit {
  isLoading: { [key: number]: boolean } = {};

  CurrentTab: number = 1;

  constructor(private ApiService: ApiService) { }

  ngOnInit(): void {
    this.isLoading[1] = false;
    this.isLoading[2] = false;
    this.isLoading[3] = false;
  }

  onClick(reportId: any) {
    this.isLoading[reportId] = true;
    this.ApiService.GetReportBlob(reportId, this.CurrentTab).pipe().subscribe(
      (response: HttpResponse<any>) => {
        const filename = this.getFilenameFromContentDisposition(
          response.body.type,
          reportId
        );
        const blob = response.body;
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.download = filename;
        anchor.href = url;
        anchor.click();
        this.isLoading[reportId] = false;
      }, error => {
        console.log(error);
        window.alert('Resource Not Found');
        this.isLoading[reportId] = false;
      })
  };

  getFilenameFromContentDisposition(
    type: string | null,
    id: number
  ): string | null {
    switch (type) {
      case 'application/pdf':
        return id == 1 ? 'ValuationReport.pdf' : id == 2 ? 'YieldReport.pdf' : 'FactSheetReport.pdf';
      case 'application/msword':
        return id == 1 ? 'ValuationReport.doc' : id == 2 ? 'YieldReport.doc' : 'FactSheetReport.doc';
      case 'application/vnd.ms-excel':
        return id == 1 ? 'ValuationReport.xls' : id == 2 ? 'YieldReport.xls' : 'FactSheetReport.xls';
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return id == 1 ? 'ValuationReport.xlsx' : id == 2 ? 'YieldReport.xlsx' : 'FactSheetReport.xlsx';
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return id == 1 ? 'ValuationReport.docx' : id == 2 ? 'YieldReport.docx' : 'FactSheetReport.docx';
      case 'application/vnd.ms-word.document.macroEnabled.12':
        return id == 1 ? 'ValuationReport.docm' : id == 2 ? 'YieldReport.docm' : 'FactSheetReport.docm';
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
        return id == 1 ? 'ValuationReport.dotx' : id == 2 ? 'YieldReport.dotx' : 'FactSheetReport.dotx';
      case 'application/vnd.ms-word.template.macroEnabled.12':
        return id == 1 ? 'ValuationReport.dotm' : id == 2 ? 'YieldReport.dotm' : 'FactSheetReport.dotm';
      default:
        return;
    }
  }

  activeBtn(value: number) {
    this.CurrentTab = value;
  }
}
