<body>
  <div class="register-section">
    <div class="register-wrap">
      <div class="register-left-side">
        <div class="inner_content_details text-center">
          <div class="logo_img">
            <!-- <img src="../../assets/images/unity-logo-login-white.svg" alt="" /> -->
            <img src="../../assets/images/hawksford_logo.svg" />
          </div>
          <h2 class="title text-uppercase">Welcome to HAWKSFORD</h2>
        </div>
        <ul class="terms_privacy_footer">
          <li><a href="">Terms of use</a></li>
          <li><a href="">Privacy policy</a></li>
        </ul>
      </div>
      <div class="register-right-side">
        <div class="inner_form">
          <div class="title_box">
            <h1 class="title text-uppercase">Register</h1>
          </div>
          <app-alert></app-alert>
          <!-- REGISTER SECTION -->
          <section *ngIf="screen === 'register'">
            <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="input_form">
              <div class="form-group input_field_wrap">
                <label for="firstName">First Name:</label>
                <input type="text" formControlName="firstName" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                  <div *ngIf="f.firstName.errors.required || f.firstName.errors.whitespace">
                    First Name is required
                  </div>
                </div>
              </div>
              <div class="form-group input_field_wrap">
                <label for="lastName">Last Name:</label>
                <input type="text" formControlName="lastName" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                  <div *ngIf="f.lastName.errors.required || f.lastName.errors.whitespace">
                    Last Name is required
                  </div>
                </div>
              </div>
              <div class="form-group input_field_wrap">
                <label for="email">email:</label>
                <input type="text" formControlName="email" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                  <div *ngIf="f.email.errors.required">Email is required</div>
                  <div *ngIf="f.email.errors.pattern">
                    Please enter a valid email address.
                  </div>
                </div>
              </div>
              <div class="form-group input_field_wrap">
                <label for="password">Password:</label>
                <input type="password" formControlName="password" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                  <div *ngIf="f.password.errors.required || f.password.errors.whitespace">
                    Password is required
                  </div>
                  <div *ngIf="f.password.errors.minlength">
                    Password must be at least 6 characters
                  </div>
                </div>
              </div>
              <div class="btn-wraper form-group">
                <div>
                  <button [disabled]="isresendloading" class="border-0 text-uppercase login_btn form_btn mx-1">
                    <span *ngIf="isresendloading" class="spinner-border spinner-border-sm mr-1"></span>
                    <!-- Register -->
                    {{isresendloading ? "Loading...":"Register"}}
                  </button>
                  <button routerLink="/login" class="border-0 text-uppercase login_btn form_btn mx-1">
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </section>


          <!-- OTP SECTION -->
          <section *ngIf="screen === 'OTP'">
            <form [formGroup]="OTPForm" (ngSubmit)="onSubmit()" class="input_form">
              <div class="input_field_wrap form-group">
                <label for="otp" class="input_label">OTP: </label>
                <input type="text" id="otp" formControlName="otp" placeholder="Enter 4 digit OTP"
                  [ngClass]="{ 'is-invalid': submitted && o.otp.errors }" class="form-control" maxlength="4"
                  (keypress)="numericOnly($event)" (keyup.enter)="handleEnterKey()" />
                <p class="timer"> {{ showtimer ? 'Resend Otp In ' + minutes + ' : ' + (resendTimer % 60) : "" }}</p>
                <!-- <div *ngIf="submitted && o.otp.errors" class="invalid-feedback">
                  <div *ngIf="o.otp.errors.required">OTP is required</div>
                  <div *ngIf="o.otp.errors.minlength || o.otp.errors.maxlength">
                    OTP should be exactly 4 digits long.
                  </div>
                  <div *ngIf="o.otp.errors.pattern">
                    This field should contain digits.
                  </div>
                </div> -->
              </div>
              <div class="btn-wraper form-group">
                <div>
                  <button type="button" [disabled]="showtimer" (click)="handleResendOTP()"
                    class="border-0 text-uppercase login_btn form_btn mx-1">
                    <span *ngIf="isresendloading" class="spinner-border spinner-border-sm mr-1"></span>
                    <!-- Resend OTP -->
                    {{ isresendloading ? "Loading..." : "Resend OTP" }}
                  </button>
                  <!-- <button [disabled]="isloading" class="border-0 text-uppercase login_btn form_btn mx-1">
                    <span *ngIf="isloading" class="spinner-border spinner-border-sm mr-1"></span>
                    {{isloading?"Loading...":"Confirm"}}
                  </button> -->
                  <button [disabled]="isloading || OTPForm.invalid"
                    class="border-0 text-uppercase login_btn form_btn mx-1">
                    <span *ngIf="isloading" class="spinner-border spinner-border-sm mr-1"></span>
                    <!-- Confirm -->
                    {{isloading ? "Loading..." : "Confirm"}}
                  </button>

                </div>
              </div>
            </form>
          </section>
        </div>
        <div class="right-side_footer">
          <div class="inner_box">
            <p>
              You have an account?
              <a routerLink="/login"> Login</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>