<!-- PORTFOLIO SECTION -->
<!-- <div class="prtfolio-sec"> -->
<div class="main_wrap">
  <div class="page_heading">
    <h2 class="text-uppercase page_title">Portfolio</h2>
  </div>
  <div class="mat_card">
    <div class="mat_card_title_wraper">
      <div class="mat_card_ttile">
        <div class="card_title ai_card_title">
          <h6 class="text-uppercase">Summary</h6>
        </div>
      </div>
      <div class="mat_card_ttile">
        <div class="card_title account_currency">
          <h6 class="">Currency USD</h6>
        </div>
      </div>
    </div>
    <div class="mat_card_content_wrap">
      <div class="mat_card_content_inner">
        <div class="ai-date-summary-content">
          <div class="content_inner">
            <span class="field_title">Date</span>
            <p class="field_details">{{ Data.registrationDate }}</p>
          </div>
          <div class="content_inner">
            <span class="field_title">Account name</span>
            <p class="field_details">
              <!-- Chris Clark Offhsore PA -->
              {{ Data.name }}
              <!-- <a href="javascript: void(0);" (click)="
                  open(modal.accountNameModal, {
                    ariaLabelledBy: 'modal-basic-title'
                  })
                " class="pop-up-btn"><i class="fa-solid fa-pen"></i></a> -->
            </p>
          </div>
        </div>
      </div>
      <div class="mat_card_content_inner">
        <div class="ai-date-summary-content">
          <div class="content_inner">
            <span class="field_title">Account number</span>
            <p class="field_details">{{ Data.accountNumber }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mat_card">
    <div class="mat_card_title_wraper">
      <div class="mat_card_ttile">
        <div class="card_title ai_card_title">
          <h6 class="text-uppercase">Account Summary</h6>
        </div>
      </div>
    </div>
    <div class="mat_card_content_wrap">
      <div class="acount_smary_flexwrap">
        <div class="account_content_short_wrap text-center">
          <h6 class="acc_content_label">Total invested</h6>
          <p class="acc_value_label">{{ Data.totalInvested }}</p>
        </div>
        <div class="account_content_short_wrap text-center">
          <h6 class="acc_content_label">Currency</h6>
          <p class="acc_value_label">{{ Data.currency }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="mat_card table_mat_card">
    <div class="mat_card_title_wraper">
      <div class="mat_card_ttile">
        <div class="card_title ai_card_title">
          <h6 class="text-uppercase">Holdings</h6>
        </div>
      </div>
      <!-- <div class="mat_card_ttile">
        <div class="card_title ai_card_title">
          <a href="javascript: void(0);">
            <i class="fa-solid fa-sliders" (click)="handleIsFilter()"></i>
          </a>
        </div>
      </div> -->
    </div>
    <div class="acccount_data_table">
      <table class="account_table">
        <thead class="data-header">
          <tr>
            <th>Instrument Name</th>
            <th>Acquisition Date</th>
            <th>Number of Shares</th>
            <!-- <th>Days in Fund</th> -->
            <th>NAV Price</th>
            <th></th>
          </tr>
        </thead>
        <tbody class="data_deatils" *ngIf="Holdings.length !== 0">
          <tr *ngFor="let dataItem of Holdings; let i = index">
            <td>{{ dataItem.instrumentName }}</td>
            <td>{{ dataItem.acquisitionDate }}</td>
            <td>{{ dataItem.noOfShares }}</td>
            <!-- <td>{{ dataItem.daysInFund }}</td> -->
            <td>{{ dataItem.sharePremiumPrice }}</td>
            <td>
              <div class="tabel_batn_wraper tabel_btn_group">
                <a href="javascript: void(0);" class="text-uppercase btn_hover_effect text-center" (click)="
                    open(modal.redeemModal, dataItem.instrumentName, dataItem.fundType, {
                      ariaLabelledBy: 'modal-sec-title',
                      animation: true,
                      size: '20',
                      scrollable: true
                    })
                  " matRipple>
                  Redeem
                </a>
                <a href="javascript: void(0);" class="text-uppercase btn_hover_effect text-center" (click)="
                    open(modal.subscribeModal, dataItem.instrumentName, dataItem.fundType, {
                      ariaLabelledBy: 'modal-sec-title',
                      animation: true,
                      size: '20',
                      scrollable: true
                    })
                  " matRipple style="margin-left: 20px;">
                  Invest
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <p *ngIf="Holdings.length === 0" style="text-align: center;  margin-top: 12px;">No Record Found</p>
    </div>
  </div>


  <!-- HOLDING FILTER SIDEBAR-->
  <!-- class for open sidebar "show" -->
  <!-- <div [ngClass]="{ options_sidebar: true, show: isFilter }">
    <div class="heading_">
      <h4 class="title text-uppercase">Holdings display options</h4>
      <div><i class="fa-solid fa-xmark" (click)="handleIsFilter()"></i></div>
    </div>
    <div class="prgh_title text-uppercase">
      <p>Which information would you like to be made available to you?</p>
    </div>
    <div class="option_list">
      <form [formGroup]="filterForm" (ngSubmit)="submitForm()">
        <ul class="inner_wrap">
          <li>
            <div class="radio_btn_wraper">
              <input type="checkbox" id="list1" formControlName="instrumentName" [value]="'instrumentName'"
                (change)="updateCheckboxArray($event, 'instrumentName')"
                [(ngModel)]="filterForm.value.instrumentName" />
              <label for="list1"> </label>
              <span class="rippal_effect"></span>
            </div>
            <span class="list_name_title">Instrument Name</span>
          </li>
          <li>
            <div class="radio_btn_wraper">
              <input type="checkbox" id="list2" formControlName="acquisitionDate" [value]="'acquisitionDate'"
                (change)="updateCheckboxArray($event, 'acquisitionDate')"
                [(ngModel)]="filterForm.value.acquisitionDate" />
              <label for="list2"> </label>
              <span class="rippal_effect"></span>
            </div>
            <span class="list_name_title">Acquisition Date</span>
          </li>
          <li>
            <div class="radio_btn_wraper">
              <input type="checkbox" id="list3" formControlName="numberOfShares" [value]="'numberOfShares'"
                (change)="updateCheckboxArray($event, 'numberOfShares')"
                [(ngModel)]="filterForm.value.numberOfShares" />
              <label for="list3"> </label>
              <span class="rippal_effect"></span>
            </div>
            <span class="list_name_title">Number of Shares</span>
          </li>
          <li>
            <div class="radio_btn_wraper">
              <input type="checkbox" id="list4" formControlName="currentMarketValue" [value]="'currentMarketValue'"
                (change)="updateCheckboxArray($event, 'currentMarketValue')"
                [(ngModel)]="filterForm.value.currentMarketValue" />
              <label for="list4"> </label>
              <span class="rippal_effect"></span>
            </div>
            <span class="list_name_title">Current Market Value</span>
          </li>
          <li>
            <div class="radio_btn_wraper">
              <input type="checkbox" id="list5" formControlName="daysInFund" [value]="'daysInFund'"
                (change)="updateCheckboxArray($event, 'daysInFund')" [(ngModel)]="filterForm.value.daysInFund" />
              <label for="list5"> </label>
              <span class="rippal_effect"></span>
            </div>
            <span class="list_name_title">Days in Fund</span>
          </li>
          <li>
            <div class="radio_btn_wraper">
              <input type="checkbox" id="list6" formControlName="estimatedReturnToDate"
                [value]="'estimatedReturnToDate'" (change)="updateCheckboxArray($event, 'estimatedReturnToDate')"
                [(ngModel)]="filterForm.value.estimatedReturnToDate" />
              <label for="list6"> </label>
              <span class="rippal_effect"></span>
            </div>
            <span class="list_name_title">Estimated Return to Date</span>
          </li>
          <li>
            <div class="radio_btn_wraper">
              <input type="checkbox" id="list7" formControlName="blendedAnnualReturnToDate"
                [value]="'blendedAnnualReturnToDate'" (change)="
                  updateCheckboxArray($event, 'blendedAnnualReturnToDate')
                " [(ngModel)]="filterForm.value.blendedAnnualReturnToDate" />
              <label for="list7"> </label>
              <span class="rippal_effect"></span>
            </div>
            <span class="list_name_title">Blended Annual Return to Date</span>
          </li>
          <li>
            <div class="radio_btn_wraper">
              <input type="checkbox" id="list8" formControlName="estimatedValueAtNextCall"
                [value]="'estimatedValueAtNextCall'" (change)="
                  updateCheckboxArray($event, 'estimatedValueAtNextCall')
                " [(ngModel)]="filterForm.value.estimatedValueAtNextCall" />
              <label for="list8"> </label>
              <span class="rippal_effect"></span>
            </div>
            <span class="list_name_title">Estimated Value at Next Call</span>
          </li>
          <li>
            <div class="radio_btn_wraper">
              <input type="checkbox" id="list9" formControlName="totalFund" [value]="'totalFund'"
                (change)="updateCheckboxArray($event, 'totalFund')" [(ngModel)]="filterForm.value.totalFund" />
              <label for="list9"> </label>
              <span class="rippal_effect"></span>
            </div>
            <span class="list_name_title">% of Total Fund</span>
          </li>
        </ul>
        <button type="submit" class="btn_hover_effect text-center text-uppercase update_view_btn">
          Update view
        </button>
      </form>
    </div>
  </div> -->
</div>

<div class="spinner-overlay" *ngIf="isloading">
  <div class="spinner"></div>
</div>

<app-ng-basic-modal #modal></app-ng-basic-modal>