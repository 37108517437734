<ng-template #changePassword let-modal>
  <div class="modal-header">
    <h6 id="modal-basic-title" class="title_heading text-uppercase modal-title">
      Change Password
    </h6>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeicon()">
      <span aria-hidden="true"></span>
      <i aria-hidden="true" class="fa-solid fa-xmark"></i>
    </button>
  </div>
  <app-alert></app-alert>
  <div class="modal-body ">
    <form [formGroup]="changePassForm" class="input_form">
      <div class="">
        <div class="pop_up_input_wrap">
          <label>Old Password:</label>
          <input type="password" id="old_pass" formControlName="oldPassword" [ngClass]="{
              'is-invalid': isSubmittedPass && c.oldPassword.errors,
              form_control: true
            }" />
          <div *ngIf="isSubmittedPass && c.oldPassword.errors" class="invalid-feedback">
            <div *ngIf="c.oldPassword.errors.required || c.oldPassword.errors.whitespace">
              Old password is required
            </div>
          </div>
        </div>
        <div class="pop_up_input_wrap">
          <label>New Password:</label>
          <input type="password" id="new_pass" formControlName="newPassword" [ngClass]="{
              'is-invalid': isSubmittedPass && c.newPassword.errors,
              form_control: true
            }" />
          <div *ngIf="isSubmittedPass && c.newPassword.errors" class="invalid-feedback">
            <div *ngIf="c.newPassword.errors.required || c.newPassword.errors.whitespace">
              New password is required
            </div>
          </div>
        </div>
        <div class="pop_up_input_wrap">
          <label>Confirm Password:</label>
          <input type="password" id="con_name" formControlName="confirmPassword" [ngClass]="{
              'is-invalid': isSubmittedPass && c.confirmPassword.errors,
              form_control: true
            }" />
          <div *ngIf="isSubmittedPass && c.confirmPassword.errors" class="invalid-feedback">
            <div *ngIf="c.confirmPassword.errors.required || c.confirmPassword.errors.whitespace">
              Confirm password is required.
            </div>
            <div *ngIf="c.confirmPassword.errors.mustMatch">
              Passwords must match
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="btn-wrap justify-end">
      <button [disabled]="isLoading" class="details_savebtn btn_hover_effect text-uppercase"
        (click)="handleChangePassword()">
        <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1"></span>
        <!-- Confirm -->
        {{ isLoading ? "Loading...":"Confirm"}}
      </button>
    </div>
  </div>
</ng-template>

<!-- Redeem Modals -->
<ng-template #redeemModal let-modal>
  <div class="modal-header">
    <h6 id="modal-basic-title" class="title_heading text-uppercase modal-title">
      REDEEM
    </h6>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modal.dismiss('Close icon clicked')">
      <span aria-hidden="true"></span>
      <i aria-hidden="true" class="fa-solid fa-xmark"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="body_innner_wrap">
      <form [formGroup]="redeemForm" class="input_form">
        <div class="form_inner_wrap redeem_form">
          <div class="title_text">
            <h6 class="text-uppercase">
              How many shares do you want to to redeem?
            </h6>
          </div>
          <div class="pop_up_input_wrap" style="margin-top: 30px;">
            <label>Number of shares:</label>
            <input type="text" id="shares_field" formControlName="shares" [ngClass]="{
              'is-invalid': isSubmitted && f.shares.errors,
              form_control: true
            }" (input)="updateAmount()" (keypress)="numericOnly($event)" maxlength="10" />
            <div *ngIf="isSubmitted && f.shares.errors" class="invalid-feedback">
              <div *ngIf="f.shares.errors.required">
                Number is required
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="modal-footer">
    <div class="btn-wrap">
      <button class="btn_hover_effect form_button text-uppercase close_btn" (click)="handleRedeem(false)">
        close
      </button>
      <button class="btn_hover_effect form_button text-uppercase next_btn" (click)="handleFinalReview(true)">
        next
      </button>
    </div>
  </div>
</ng-template>

<!-- FINAL REVIEW POPUP FOR REDEEM-->
<ng-template #finalReview let-modal>
  <div class="modal-header">
    <h6 id="modal-basic-title" class="title_heading text-uppercase modal-title">
      final review
    </h6>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modal.dismiss('Close icon clicked')">
      <span aria-hidden="true"></span>
      <i aria-hidden="true" class="fa-solid fa-xmark"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="body_innner_wrap">
      <div class="popup-static-info_wrap">
        <div class="popup-static-info">
          <p>Fund</p>
          <p>{{txtFund}}</p>
        </div>
        <div class="popup-static-info">
          <p>Type</p>
          <p>Redeem</p>
        </div>
        <div class="popup-static-info">
          <p>Number of Shares</p>
          <p>{{ redeemForm.value.shares }}</p>
        </div>
        <div class="popup-static-info">
          <p>Currency</p>
          <p>USD</p>
        </div>
      </div>
      <form class="input_form">
        <div class="form_inner_wrap">
          <div class="flex_column_wrap">
            <div class="check_box_input">
              <div class="radio_btn_wraper">
                <input type="checkbox" id="redeemFinalReview" name="termsCondition" [(ngModel)]="isTermsCondition"
                  id="tc_check" />
                <label for="tc_check"> </label>
                <span class="rippal_effect"></span>
              </div>
            </div>
            <div class="radio_label_content">
              <p>
                I have read and accepted the
                <a href="javascript: void(0);" (click)="handleLinkClick($event)">terms and conditions</a>
              </p>
            </div>
          </div>
          <div *ngIf="isSubmitted && !isTermsCondition" class="invalid-feedback input_form_group">
            <div *ngIf="!isTermsCondition">
              Please check the terms and conditions
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="modal-footer">
    <div class="btn-wrap">
      <button class="btn_hover_effect form_button text-uppercase close_btn" (click)="handleFinalReview(false)">
        back
      </button>
      <button [disabled]="redeemIsLoading" class="btn_hover_effect form_button text-uppercase next_btn"
        (click)="handleFinalConfirmation(true, 'redeem')">
        <span *ngIf="redeemIsLoading" class="spinner-border spinner-border-sm mr-1"></span>
        {{ redeemIsLoading ? "Loading...": "Submit" }}
      </button>
    </div>
  </div>
</ng-template>

<!-- POPUP FOR SUBSCRIBE -->
<ng-template #subscribeModal let-modal>
  <div class="modal-header">
    <h6 id="modal-basic-title" class="title_heading text-uppercase modal-title">
      INVEST
    </h6>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modal.dismiss('Close icon clicked')">
      <span aria-hidden="true"></span>
      <i aria-hidden="true" class="fa-solid fa-xmark"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="body_innner_wrap">
      <form [formGroup]="subscribeForm" class="input_form">
        <div class="form_inner_wrap redeem_form">
          <div class="title_text">
            <h6 class="text-uppercase">
              How many shares do you want to to invest?
            </h6>
          </div>
          <div class="pop_up_input_wrap" style="margin-top: 30px;">
            <label>Number of shares:</label>
            <input type="text" id="shares_field" formControlName="shares" [ngClass]="{
              'is-invalid': isSubmitted && s.shares.errors,
              form_control: true
            }" (input)="updateAmount()" (keypress)="numericOnly($event)" maxlength="10" />
            <div *ngIf="isSubmitted && s.shares.errors" class="invalid-feedback">
              <div *ngIf="s.shares.errors.required">
                Number is required
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="modal-footer">
    <div class="btn-wrap">
      <button class="btn_hover_effect form_button text-uppercase close_btn" (click)="handleSubscribe(false)">
        close
      </button>
      <button class="btn_hover_effect form_button text-uppercase next_btn" (click)="handleReview(true)">
        next
      </button>
    </div>
  </div>
</ng-template>

<!--REVIEW POPUP FOR SUBSCRIBE  -->
<ng-template #review let-modal>
  <div class="modal-header">
    <h6 id="modal-basic-title" class="title_heading text-uppercase modal-title">
      review
    </h6>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modal.dismiss('Close icon clicked')">
      <span aria-hidden="true"></span>
      <i aria-hidden="true" class="fa-solid fa-xmark"></i>
    </button>
  </div>
  <div class="modal-body ">
    <div class="body_innner_wrap">
      <div class="popup-static-info_wrap">
        <div class="popup-static-info">
          <p>Fund</p>
          <p>{{txtFund}}</p>
        </div>
        <div class="popup-static-info">
          <p>Type</p>
          <p>Invest</p>
        </div>
        <div class="popup-static-info">
          <p>Number of Shares</p>
          <p>{{ subscribeForm.value.shares }}</p>
        </div>
        <div class="popup-static-info">
          <p>Currency</p>
          <p>USD</p>
        </div>
      </div>
      <form action="">
        <div class="form_inner_wrap">
          <div class="flex_column_wrap">
            <div class="check_box_input">
              <div class="radio_btn_wraper">
                <input type="checkbox" id="subscriptionRevivew" name="termsCondition" [(ngModel)]="isTermsCondition"
                  id="tc_check" />
                <label for="tc_check"> </label>
                <span class="rippal_effect"></span>
              </div>
            </div>
            <div class="radio_label_content">
              <p>
                I have read and accepted the
                <a href="javascript: void(0);" (click)="handleLinkClick($event)">terms and conditions</a>
              </p>
            </div>
          </div>
          <div *ngIf="isSubmitted && !isTermsCondition" class="invalid-feedback input_form_group">
            <div *ngIf="!isTermsCondition">
              Please check the terms and conditions
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="modal-footer">
    <div class="btn-wrap">
      <button class="btn_hover_effect form_button text-uppercase close_btn" (click)="handleReview(false)">
        back
      </button>
      <button [disabled]="investIsLoading" class="btn_hover_effect form_button text-uppercase next_btn"
        (click)="handleFinalConfirmation(true, 'subscription')">
        <span *ngIf="investIsLoading" class="spinner-border spinner-border-sm mr-1"></span>
        {{ investIsLoading ? "Loading..." : "Submit" }}
      </button>
    </div>
  </div>
</ng-template>

<!--FINAL CONFIRMATION POPUP FOR REDEEM AND SUBSCRIPTION  -->
<ng-template #finalConfirmation let-modal>
  <div class=" modal-header ">
    <h6 id="modal-basic-title" class="title_heading text-uppercase modal-title">
      Final Confirmation
    </h6>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modal.dismiss('Close icon clicked')">
      <span aria-hidden="true"></span>
      <i aria-hidden="true" class="fa-solid fa-xmark"></i>
    </button>
  </div>
  <div class="modal-body confirmation_modal_body ">
    <div class="body_innner_wrap">
      <img src="../../assets/images/unity-checked-circle.svg" alt="" />
      <h4>
        <!-- Thank you, the team at Griffon Solutions have received your -->
        Thank you, the team at Hawksford (Mauritius) Limited have received your
        <span *ngIf="isRedeem">redemption</span>
        <span *ngIf="isInvest">subscription</span> notification.
        <!-- "subscription"  -->
      </h4>
      <!-- redeem pargh -->
      <p *ngIf="isRedeem">
        We will send you a redemption form to be executed by the authorised signatories. Please note that redemption
        from this fund takes a minimum of 2 business days. The Administrator must receive the signed redemption form 2
        Business Days prior the

        redemption date by 12:00 pm, Mauritius time, to receive the current trade date. We will send you a redemption
        confirmation once redemption processed on our end
      </p>

      <!-- subscription pargh  -->
      <p *ngIf="isInvest" class="">
        We will send you a top up form to be executed by the authorised signatories. The Top Up Form must be received by
        the Administrator no later than 12:00 pm (Mauritius time) 2 Business Days before the relevant Subscription Date.
        Upon receipt of funding, we will allocate your account the allotted shares. Once your shares have been allocated
        you will receive a subscription confirmation and the shares will be in your account and viewable on the
        platform.
      </p>
    </div>
  </div>
  <div class="modal-footer ">
    <div class="btn-wrap confirmation_modal_footer">
      <button class="btn_hover_effect form_button text-uppercase close_btn"
        (click)="handleFinalConfirmation(false, 'close')">
        close
      </button>
    </div>
  </div>
</ng-template>

<ng-template #finalTermsAndCondition let-modal>
  <div class=" modal-header ">
    <h6 id="modal-basic-title" class="title_heading text-uppercase modal-title">
      Terms and conditions
    </h6>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modal.dismiss('Close icon clicked')">
      <span aria-hidden="true"></span>
      <i aria-hidden="true" class="fa-solid fa-xmark"></i>
    </button>
  </div>
  <div class="modal-body confirmation_modal_body ">
    <div class="body_innner_wrap">
      <!-- redeem pargh -->
      <p *ngIf="isRedeem">
        All redemption proceeds will be sent to your primary bank account on file. Any material outdated due diligence
        documentation will need to be updated prior to redemption, as per the prevailing Anti-Money Laundering
        regulations.
      </p>

      <!-- subscription pargh  -->
      <p *ngIf="isInvest" class="">
        At time of top-up, any outdated due diligence documentation will be required.
      </p>
      <p *ngIf="isInvest" class=""> Subscription moneys received by transfer from a bank account will only be accepted
        if such transfer is clearly
        identifiable as coming from the bank account of the applicant(s). A subscription for the shares will not be
        processed and such shares will not be allotted until receipt of notification that a prospective investor's funds
        have been cleared in the full amount of the subscription.</p>
    </div>
  </div>
  <div class="modal-footer ">
    <div class="btn-wrap confirmation_modal_footer">
      <button class="btn_hover_effect form_button text-uppercase close_btn"
        (click)="modal.dismiss('Close icon clicked')">
        ok
      </button>
    </div>
  </div>
</ng-template>

<ng-template #AboutUs let-modal>
  <div class=" modal-header ">
    <h6 id="modal-basic-title" class="title_heading text-uppercase modal-title">
      About Us
    </h6>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modal.dismiss('Close icon clicked')">
      <span aria-hidden="true"></span>
      <i aria-hidden="true" class="fa-solid fa-xmark"></i>
    </button>
  </div>
  <div class="modal-body confirmation_modal_body ">
    <div class="body_innner_wrap">
      <p>
        The Fund is a public company, limited by shares incorporated in the Republic of Mauritius and holds a Global
        Business Licence issued by the Mauritius Financial Services Commission. The Fund is authorised to operate as a
        Collective Investment Scheme under the Securities Act 2005 and is categorised as an Expert Fund under the
        Securities (Collective Investment Schemes and Closed end Funds) 2008. The registered office of the Fund is
        situated at c/o Hawksford (Mauritius) Limited, C2-401, 4th Floor, Grand Baie La Croisette, Grand Baie,
        Mauritius, and the Administrator is Hawksford (Mauritius) Limited.
      </p>
    </div>
  </div>
  <div class="modal-footer ">
    <div class="btn-wrap confirmation_modal_footer">
      <button class="btn_hover_effect form_button text-uppercase close_btn"
        (click)="modal.dismiss('Close icon clicked')">
        close
      </button>
    </div>
  </div>
</ng-template>

<ng-template #Support let-modal>
  <div class=" modal-header ">
    <h6 id="modal-basic-title" class="title_heading text-uppercase modal-title">
      Support
    </h6>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modal.dismiss('Close icon clicked')">
      <span aria-hidden="true"></span>
      <i aria-hidden="true" class="fa-solid fa-xmark"></i>
    </button>
  </div>
  <div class="modal-body confirmation_modal_body ">
    <div class="body_innner_wrap">
      <p>Hawksford (Mauritius) Limited </p>
      <p> C2-401, 4th Floor </p>
      <p>Grand Baie La Croisette</p>
      <p>Grand Baie</p>
      <p>Mauritius</p>
      <p>Attention: Pooja Jadaw</p>
      <p>Tel: (230) 269 4888</p>
      <p>Fax: (230) 269 6205</p>
      <p>Email : <a href="mailto:omusdmmf@hawksford.com" style="color: white;">omusdmmf@hawksford.com</a></p>
    </div>
  </div>
  <div class="modal-footer ">
    <div class="btn-wrap confirmation_modal_footer">
      <button class="btn_hover_effect form_button text-uppercase close_btn"
        (click)="modal.dismiss('Close icon clicked')">
        close
      </button>
    </div>
  </div>
</ng-template>