import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  template: `
    <footer class="footer">
      <div class="container content has-text-centered">
        <p>Copyright &copy; of DMA 2020</p>
      </div>
    </footer>
  `,
  styleUrls: ['/node_modules/bulma/css/bulma.css']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
