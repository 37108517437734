<!-- PORTFOLIO SECTION -->
<!-- <div class="prtfolio-sec"> -->
<div class="main_wrap">
  <div class="page_heading">
    <h2 class="text-uppercase page_title">Profile</h2>
  </div>
  <form (ngSubmit)="onSubmit()" [formGroup]="UpdateForm">
    <app-alert></app-alert>
    <div class="mat_card">

      <div class="mat_card_content_wrap">

        <div class="mat_card_content_inner">
          <div class="profile_avtar_wraper">
            <div class="avatar-upload"
              [style.backgroundImage]="profileUrl === '' || profileUrl === null ? 'url(../../assets/images/default-user.png)' :'url(' + profileUrl + ')' ">
              <input type="file" id="file_select" (change)="onProfilePictureChanged($event)" accept=".png, .jpg, .jpeg"
                formControlName="profileUrl">
              <label for="file_select" class="avtar_img_icon">
                <img src="../../assets/images/camera.png" alt="">
              </label>
            </div>
          </div>
          <div class="ai-date-summary-content">
            <div class="content_inner">
              <span class="field_title">First Name</span>
              <input type="text" name="" id="" formControlName="firstName"
                [ngClass]="{ 'is-invalid': submitted && u.firstName.errors }" />
              <div *ngIf="submitted && u.firstName.errors" class="invalid-feedback">
                <div *ngIf="u.firstName.errors.required">
                  First Name is required
                </div>
              </div>
            </div>
          </div>
          <div class="ai-date-summary-content">
            <div class="content_inner">
              <span class="field_title">Last Name</span>
              <input type="text" name="" id="" formControlName="lastName"
                [ngClass]="{ 'is-invalid': submitted && u.lastName.errors }" />
              <div *ngIf="submitted && u.lastName.errors" class="invalid-feedback">
                <div *ngIf="u.lastName.errors.required">
                  Last Name is required
                </div>
              </div>
            </div>
          </div>
          <div class="ai-date-summary-content">
            <div class="content_inner">
              <span class="field_title">Email Address</span>
              <input type="email" class="readonly" name="" id="" [readonly]="true" formControlName="email" />
            </div>
          </div>

          <div class="ai-date-summary-content">
            <div class="content_inner">
              <span class="field_title">Sequential Number </span>
              <input type="text" class="readonly" name="" id="" [readonly]="true" formControlName="sequentialNumber" />
            </div>
          </div>
          <div class="ai-date-summary-content">
            <div class="content_inner">
              <span class="field_title">Account Number </span>
              <input type="text" class="readonly" name="" id="" [readonly]="true" formControlName="accountNumber" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="btn-wrap justify-end">
        <button class="details_savebtn btn_hover_effect text-uppercase">
          <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1"></span>
          {{ isLoading ? "Loading..." : "Update" }}
        </button>
      </div>
    </div>
  </form>

  <div class="spinner-overlay" *ngIf="isLoader">
    <div class="spinner"></div>
  </div>