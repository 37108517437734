import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../_models/user';
import { AuthenticationService } from '../_services/authentication.service';
import { ApiService } from 'src/app/api.service';
import { formatDate } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgBasicModalComponent } from '../_components/ng-basic-modal/ng-basic-modal.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['header.component.css'],
})
export class HeaderComponent implements OnInit {
  currentUser: User;
  isDropDown: boolean = false;
  // isSideBar: boolean = false;
  @Input() getUserData: any
  @Input() isSideBar: boolean;
  @Output() isHeaderToggleSideBar: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private service: ApiService,
    private modalService: NgbModal
  ) {
    this.authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );
  }

  trigger() {
    const date = { update: formatDate(new Date(), 'yyyy-MM-dd', 'en') };
    this.service
      .NAVbutton(date)
      .pipe()
      .subscribe((result) => {
        window.location.reload();
        return result;
      });
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  ngOnInit(): void { }
  public myFunction(event: any) {
    console.log(event);
  }

  ngDoCheck() { }

  handleDropDown() {
    this.isDropDown = !this.isDropDown;
  }

  handleMobileSidebar() {
    this.isHeaderToggleSideBar.emit(!this.isSideBar);
  }

  @ViewChild('modal') child: NgBasicModalComponent;
  open(content, optionSet?) {
    this.child.checkSubscriptionStatus();
    this.modalService.open(content, optionSet);
  }
}
