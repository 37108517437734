import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import { AlertService } from '../_services/alert.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-forget',
  templateUrl: './forget.component.html',
  styleUrls: ['./forget.component.css'],
})
export class ForgetComponent implements OnInit {
  forgetForm: FormGroup;
  OTPForm: FormGroup;
  newPassForm: FormGroup;
  userEmail: any;
  verificaionCode: any;
  messageId: any;
  loading = false;
  isloading = false;
  submitted = false;
  returnUrl: string;
  incorrect = false;
  screen = 'forget';
  resendTimer: number = 120;
  minutes: number = 0;
  showtimer = false

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.forgetForm = this.formBuilder.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}'),
        ]),
      ],
    });

    this.OTPForm = this.formBuilder.group({
      otp: ['', Validators.required /* , Validators.minLength(4) */],
    });

    this.newPassForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirmPass: ['', Validators.required],
    }, {
      validator: this.MustMatch('password', 'confirmPass')
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.forgetForm.controls;
  }

  get o() {
    return this.OTPForm.controls;
  }

  get n() {
    return this.newPassForm.controls;
  }


  resetForm(controls) {
    Object.keys(controls).forEach((key) => {
      controls[key].setErrors(null);
      controls[key].setValue('');
    });
  }

  sendOTP() {
    this.authenticationService.ForgotPassword(this.userEmail)
      .pipe(first())
      .subscribe(
        data => {
          this.submitted = false;
          this.screen = 'OTP';
          this.loading = false;

          // RESET FORM VALUE AND ERRORs.
          // this.forgetForm.reset();
          // this.resetForm(this.forgetForm.controls);

          this.alertService.success('Verification code was successfully sent to your email.', true);

          this.forgetForm.reset();
        },
        error => {
          this.alertService.error(error, true);
          this.loading = false;
        });
  }

  resetResendTimer() {
    this.resendTimer = 120;
    this.showtimer = true

    const timerInterval = setInterval(() => {
      this.resendTimer--;

      this.minutes = Math.floor(this.resendTimer / 60);

      if (this.resendTimer <= 0) {
        this.showtimer = false
        clearInterval(timerInterval);
      }
    }, 1000);
  }

  handleResendOTP() {
    this.submitted = false;
    this.OTPForm.reset();
    this.alertService.clear();
    this.loading = true;
    this.resetResendTimer()
    this.sendOTP()
  }

  numericOnly(event): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  handleEnterKey() {
    // Programmatically click the Confirm button
    if (!this.isloading && !this.OTPForm.invalid) {
      this.onSubmit();
    }
  }

  onSubmit() {
    this.alertService.clear();
    this.submitted = true;


    if (this.screen == 'forget') {
      if (this.forgetForm.invalid) {
        return;
      }
      this.userEmail = this.forgetForm.value
      this.loading = true;
      this.sendOTP()
    } else if (this.screen === 'OTP') {
      if (this.OTPForm.invalid) {
        return;
      }
      this.isloading = true;
      let data = {
        email: this.userEmail.email,
        code: this.OTPForm.value.otp
      }
      this.authenticationService.VerifyResetPasswordCode(data)
        .pipe(first())
        .subscribe(
          (data: any) => {
            this.screen = 'newPassword'
            this.isloading = false;

            this.messageId = data.data.messageId
            this.verificaionCode = this.OTPForm.value.otp
            this.submitted = false;

            // RESET FORM VALUE AND ERRORs.
            this.OTPForm.reset();
            // this.resetForm(this.forgetForm.controls);
          },
          error => {
            this.isloading = false;
            this.alertService.error(error, true);
          });
    } else if (this.screen == 'newPassword') {
      if (this.newPassForm.invalid) {
        return;
      }
      this.isloading = true;
      let data = {
        "email": this.userEmail.email,
        "code": this.verificaionCode,
        "messageId": this.messageId,
        "password": this.newPassForm.value.password
      }
      this.authenticationService.ResetPassword(data)
        .pipe(first())
        .subscribe(
          data => {
            this.screen = 'verification'
            this.isloading = false;
            this.submitted = false;

            // RESET FORM VALUE AND ERRORs.
            this.newPassForm.reset();
            // this.resetForm(this.forgetForm.controls);
          },
          error => {
            this.isloading = false;
            this.alertService.error(error, true);
          });
    }
  }
}
