import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AlertService } from '../_services/alert.service';
import { AuthenticationService } from '../_services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.css'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  OTPForm: FormGroup;
  isloading = false;
  isresendloading = false;
  submitted = false;
  returnUrl: string;
  incorrect = false;
  screen = 'login';
  resendTimer: number = 120;
  minutes: number = 0;
  showtimer = false

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  resetResendTimer() {
    this.resendTimer = 120;
    this.showtimer = true

    const timerInterval = setInterval(() => {
      this.resendTimer--;

      this.minutes = Math.floor(this.resendTimer / 60);

      if (this.resendTimer <= 0) {
        this.showtimer = false
        clearInterval(timerInterval);
      }
    }, 1000);
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}'),
        ]),
      ],
      password: ['', [Validators.required, this.noWhitespaceValidator]],
    });

    this.OTPForm = this.formBuilder.group({
      otp: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(4),
          Validators.pattern('[0-9]+'),
        ]),
      ],
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  noWhitespaceValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const isWhitespace = (control.value || '').trim().length === 0;
    return isWhitespace ? { 'whitespace': true } : null;
  }

  numericOnly(event): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  handleEnterKey() {
    // Programmatically click the Confirm button
    if (!this.isloading && !this.OTPForm.invalid) {
      this.onSubmit();
    }
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  get o() {
    return this.OTPForm.controls;
  }

  sendOTP() {
    this.submitted = false;
    this.isresendloading = true;
    this.authenticationService.SignIn(this.loginForm.value)
      .pipe(first())
      .subscribe(
        data => {
          this.isresendloading = false;
          this.screen = 'OTP';
          this.alertService.success('Verification code was successfully sent to your email.', true);
        },
        error => {
          this.alertService.error(error, true);
          this.isresendloading = false;
        });
  }

  handleResendOTP() {
    this.OTPForm.reset();
    this.alertService.clear();
    this.resetResendTimer()
    this.sendOTP()
  }

  onSubmit() {
    this.alertService.clear();
    this.submitted = true;
    this.incorrect = false;
    // stop here if form is invalid
    if (
      (this.loginForm.invalid && this.screen != 'OTP') ||
      (this.OTPForm.invalid && this.screen != 'login')
    ) {
      return;
    }

    if (this.screen == 'OTP') {
      // console.log(this.OTPForm);

      // this.loading = true;
      // this.authenticationService
      //   .login('Richard', this.f.password.value)
      //   .pipe(first())
      //   .subscribe(
      //     (data) => {
      //       this.router.navigate([this.returnUrl]);
      //     },
      //     (error) => {
      //       this.alertService.error(error);
      //       this.loading = false;
      //       this.incorrect = true;
      //       this.submitted = false;
      //     }
      //   );

      this.isloading = true;
      let data = {
        username: this.loginForm.value.email,
        code: this.OTPForm.value.otp
      }

      this.authenticationService.TwoWayAuthenticate(data)
        .pipe(first())
        .subscribe(
          data => {
            this.alertService.success('Login successful', true);
            this.isloading = false;
            this.router.navigate(['/']);
          },
          error => {
            this.alertService.error(error, true);
            this.isloading = false;
          });
    } else {
      this.submitted = false;
      this.incorrect = true;
      this.sendOTP()
    }
  }
}
