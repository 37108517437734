<body>
  <div class="forget-section">
    <div class="forget-wrap">
      <div class="forget-left-side">
        <div class="inner_content_details text-center">
          <div class="logo_img">
            <!-- <img src="../../assets/images/unity-logo-login-white.svg" alt="" /> -->
            <img src="../../assets/images/hawksford_logo.svg" />
          </div>
          <h2 class="title text-uppercase">Welcome to HAWKSFORD</h2>
        </div>
        <ul class="terms_privacy_footer">
          <li><a href="">Terms of use</a></li>
          <li><a href="">Privacy policy</a></li>
        </ul>
      </div>
      <div class="forget-right-side">
        <div class="inner_form">
          <div class="title_box">
            <h1 class="title text-uppercase">Forget Password</h1>
          </div>
          <app-alert></app-alert>

          <!-- FORGET SECTION -->
          <section *ngIf="screen === 'forget'">
            <form [formGroup]="forgetForm" (ngSubmit)="onSubmit()" class="input_form">
              <div class="input_field_wrap form-group">
                <p>
                  Please enter your email address below to reset your forgotten
                  or lost password.
                </p>
              </div>
              <div class="input_field_wrap form-group">
                <label for="email" class="input_label">email: </label>
                <input type="email" id="emial_field" formControlName="email"
                  [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                  <div *ngIf="f.email.errors.required">Email is required</div>
                  <div *ngIf="f.email.errors.pattern">
                    Please enter a valid email address.
                  </div>
                </div>
              </div>

              <div class="btn-wraper form-group">
                <button [disabled]="loading" class="border-0 text-uppercase login_btn form_btn">
                  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                  <!-- Reset Password -->
                  {{ loading ? "Loading..." : "Reset Password" }}
                </button>
                <a routerLink="/login" class="text-uppercase forgot_password_btn form_btn">BACK TO LOGIN</a>
              </div>
            </form>
          </section>

          <!-- OTP SECTION -->
          <form [formGroup]="OTPForm" (ngSubmit)="onSubmit()" class="input_form">
            <section *ngIf="screen === 'OTP'">
              <div class="input_field_wrap form-group">
                <label for="otp" class="input_label">OTP: </label>
                <input type="text" id="otp" formControlName="otp" placeholder="Enter 4 digit OTP"
                  [ngClass]="{ 'is-invalid': submitted && o.otp.errors }" maxlength="4" (keypress)="numericOnly($event)"
                  (keyup.enter)="handleEnterKey()" />
                <p class="timer"> {{ showtimer ? "Resend Otp In " + minutes + " : " + (resendTimer % 60) : ""}}
                </p>
                <!-- <div *ngIf="submitted && o.otp.errors" class="invalid-feedback">
                  <div *ngIf="o.otp.errors.required">OTP is required</div>
                </div> -->
              </div>
              <div class="btn-wraper form-group">
                <div>
                  <button type="button" [disabled]="showtimer" (click)="handleResendOTP()"
                    class="border-0 text-uppercase login_btn form_btn mx-1">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    <!-- Resend OTP -->
                    {{ loading ? "Loading..." : "Resend OTP" }}
                  </button>
                  <!-- <button [disabled]="isloading" class="border-0 text-uppercase login_btn form_btn mx-1">
                    <span *ngIf="isloading" class="spinner-border spinner-border-sm mr-1"></span>
                    {{ isloading?"Loading...":"Confirm" }}
                  </button> -->
                  <button [disabled]="isloading || OTPForm.invalid"
                    class="border-0 text-uppercase login_btn form_btn mx-1">
                    <span *ngIf="isloading" class="spinner-border spinner-border-sm mr-1"></span>
                    <!-- Confirm -->
                    {{ isloading ? "Loading..." : "Confirm" }}
                  </button>
                </div>
              </div>
            </section>
          </form>

          <!-- NEW PASSWORD SECTION -->
          <form [formGroup]="newPassForm" (ngSubmit)="onSubmit()" class="input_form">
            <section *ngIf="screen === 'newPassword'">
              <div class="input_field_wrap form-group">
                <label for="password" class="input_label">New Password: </label>
                <input type="password" id="password" formControlName="password" placeholder="Enter new password"
                  [ngClass]="{ 'is-invalid': submitted && n.password.errors }" />
                <div *ngIf="submitted && n.password.errors" class="invalid-feedback">
                  <div *ngIf="n.password.errors.required">
                    New Password is required
                  </div>
                </div>
              </div>
              <div class="input_field_wrap form-group">
                <label for="confirmPass" class="input_label">Confirm Password:
                </label>
                <input type="password" id="confirmPass" formControlName="confirmPass"
                  placeholder="Enter confirm password" [ngClass]="{
                    'is-invalid': submitted && n.confirmPass.errors
                  }" />
                <div *ngIf="submitted && n.confirmPass.errors" class="invalid-feedback">
                  <div *ngIf="n.confirmPass.errors.required">
                    Confirm Password is required
                  </div>
                  <div *ngIf="n.confirmPass.errors.mustMatch">
                    Passwords must match
                  </div>
                </div>
              </div>
              <div class="btn-wraper form-group">
                <div>
                  <button [disabled]="isloading" class="border-0 text-uppercase login_btn form_btn mx-1">
                    <span *ngIf="isloading" class="spinner-border spinner-border-sm mr-1"></span>
                    <!-- Confirm -->
                    {{ isloading ? "Loading..." : "Confirm" }}
                  </button>
                </div>
              </div>
            </section>
          </form>

          <!-- Verification SECTION -->
          <section *ngIf="screen === 'verification'">
            <div>
              <div class="confirmation-container">
                <!-- <i class="fa-regular fa-circle-check"></i> -->
                <img class="conf_img" src="../../assets/images/unity-checked-circle.svg" alt="check-image" />
                <p class="title">Forgot password confirmation.</p>
              </div>
              <p>Please check your email to reset your password.</p>

              <form [formGroup]="OTPForm" (ngSubmit)="onSubmit()" class="input_form back_to_login_btn">
                <div class="btn-wraper form-group">
                  <div>
                    <button routerLink="/login" [disabled]="loading"
                      class="border-0 text-uppercase login_btn form_btn mx-1">
                      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                      Back to login
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</body>