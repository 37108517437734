<head>
  <!-- For Icons -->
  <link rel="stylesheet" href="/assets/css/global.css" />
  <link rel="stylesheet" href="/assets/css/all.min.css" />
</head>

<router-outlet></router-outlet>

<!-- <div
  bsModal
  #childModal="bs-modal"
  class="modal fade"
  tabindex="1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-child-name" class="modal-title pull-left">
          You Have Been Idle!
        </h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="hideChildModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{ idleState }}
        <div class="row mrgbtm">
          <div class="col-md-6">
            <button type="button" (click)="logout()" class="btn btn-danger">
              Logout
            </button>
          </div>
          <div class="col-md-6">
            <button type="button" (click)="stay()" class="btn btn-success">
              Stay
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<ng-template #childModal let-modal>
  <div class="modal-header">
    <h4 id="modal-basic-title" class="title_heading text-uppercase modal-title">
      Do you want to?
    </h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modal.dismiss('Close icon clicked')"
    >
      <span aria-hidden="true"></span>
      <i aria-hidden="true" class="fa-solid fa-xmark"></i>
    </button>
  </div>
  <div class="modal-body ">
    {{ idleState }}
    <div class="d-flex mrgbtm justify-content-between">
      <div class="">
        <button type="button" (click)="logout()" class="btn btn-danger">
          Logout
        </button>
      </div>
      <div class="">
        <button type="button" (click)="stay()" class="btn btn-success">
          Stay
        </button>
      </div>
    </div>
  </div>
</ng-template>
