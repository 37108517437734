<body>
  <div class="change-password-section">
    <div class="change-password-wrap">
      <div class="change-password-left-side">
        <div class="inner_content_details text-center">
          <div class="logo_img">
            <img src="../../assets/images/unity-logo-login-white.svg" alt="" />
          </div>
          <h2 class="title text-uppercase">Welcome to HWKSFORD</h2>
          <p class="details">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt.
          </p>
        </div>
        <ul class="terms_privacy_footer">
          <li><a href="">Terms of use</a></li>
          <li><a href="">Privacy policy</a></li>
        </ul>
      </div>
      <div class="change-password-right-side">
        <div class="inner_form">
          <div class="title_box">
            <h1 class="title text-uppercase">Change Password</h1>
          </div>
          <app-alert></app-alert>
          <!-- LOGIN SECTION -->
          <section>
            <form [formGroup]="changePassForm" (ngSubmit)="onSubmit()" class="input_form">
              <div class="input_field_wrap form-group">
                <label for="oldPassword" class="input_label">Old Password:
                </label>
                <input type="password" id="emial_field" formControlName="oldPassword" [ngClass]="{
                    'is-invalid': submitted && f.oldPassword.errors
                  }" />
                <div *ngIf="submitted && f.oldPassword.errors" class="invalid-feedback">
                  <div *ngIf="f.oldPassword.errors.required">
                    Old password is required
                  </div>
                </div>
              </div>
              <div class="input_field_wrap form-group">
                <label for="password_field" class="input_label">New Password:
                </label>
                <input type="password" id="password_field" formControlName="newPassword" [ngClass]="{
                    'is-invalid': submitted && f.newPassword.errors
                  }" />
                <div *ngIf="submitted && f.newPassword.errors" class="invalid-feedback">
                  <div *ngIf="f.newPassword.errors.required">
                    New newPassword is required
                  </div>
                </div>
              </div>
              <div class="input_field_wrap form-group">
                <label for="password_field" class="input_label">Confirm Password:
                </label>
                <input type="password" id="password_field" formControlName="confirmPassword" [ngClass]="{
                    'is-invalid': submitted && f.confirmPassword.errors
                  }" />
                <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                  <div *ngIf="f.confirmPassword.errors.required">
                    Confirm password is required.
                  </div>
                  <div *ngIf="f.confirmPassword.errors.mustMatch">
                    Passwords must match
                  </div>
                </div>
              </div>
              <div *ngIf="incorrect" style="
                  color: #dc3545;
                  font-size: 80%;
                  margin-top: 0.25rem;
                  width: 100%;
                ">
                Incorrect old password.
              </div>

              <div class="btn-wraper form-group">
                <button [disabled]="loading" class="border-0 text-uppercase login_btn form_btn">
                  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                  <!-- Confirm -->
                  {{ loading ? "Loading..." : "Confirm" }}
                </button>
              </div>
            </form>
          </section>
        </div>
      </div>
    </div>
  </div>
</body>