<head>
  <!-- For Icons -->
  <link rel="stylesheet" href="/assets/css/all.min.css" />
</head>

<div [ngClass]="{
    'side-bar-menu': true,
    'side-bar-menu-show': isSideBar,
    'side-bar-menu-closed': isSideBar
  }">
  <div class="side-menu-wrap">
    <div class="logo-content-wrapper">
      <a href="javascript: void(0);">
        <!-- <img src="../../assets/images/unity-logo-navigation-white.svg" alt="" /> -->
        <img src="../../assets/images/hawksford_logo.svg" />
        <img src="../../assets/images/Hawksford-symbol-white.svg" alt="" class="symbol_icon" />
      </a>
      <a href="javascript: void(0);" (click)="handleSideBar()" class="sidebar_l_toggle_btn">
        <i *ngIf="isSideBar" class="fa-solid fa-angle-right"></i>
        <i *ngIf="!isSideBar" class="fa-solid fa-angle-left"></i>
      </a>
      <div class="sidebar_close_mobile_btn" (click)="handleSideBar()">
        <i class="fa-solid fa-xmark"></i>
      </div>
    </div>
    <div class="sidebar-profile-wrapper">
      <!-- <div class="profile_img"></div> -->
      <div class="profile_img"
        [style.backgroundImage]="getUserData.profileUrl ? 'url(' + getUserData.profileUrl + ')' : 'url(../../assets/images/default-user.jpg)'">
      </div>
      <h6 class="profile_text">{{ getUserData.firstName }} {{ getUserData.lastName }}</h6>
    </div>
    <div class="inner-side-nav-wrap">
      <div class="sidenav-account-info">
        <!-- DropDown set up -->
        <div ngbDropdown>
          <a (click)="handleDropDown()" href="javascript:void(0);" id="dropdownBasic1" ngbDropdownToggle
            aria-controls="basic-link-dropdown" class="user_details profile_menu_btn">
            <span class="text-uppercase profile_text">{{ getUserData?.firstName?.slice(0,1) }}{{
              getUserData?.lastName?.slice(0,1) }}</span>
            <div class="account__dets">
              <h4 class="text-uppercase">{{ getUserData.firstName }}</h4>
              <p class="account-id"> Sequential Number : {{ getUserData.sequentialNumber }}</p>
            </div>
            <!-- <div class="view-more-accounts view_accounts_btn">
              <i *ngIf="!isDropDown" class="fa-solid fa-angle-down"></i>
              <i *ngIf="isDropDown" class="fa-solid fa-angle-up"></i>
            </div> -->
          </a>
          <!-- <div class="profile_dropdown_menu dropdown-menu acc_dets_drop_down account-drop-down dropdown-menu"
            id="basic-link-dropdown" ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <ul>
              <li ngbDropdownItem>
                <a href="##" class="account-change-link">
                  <span class="text-uppercase account_badge">an</span>
                  <div class="account_details">
                    <h4 class="account_name_list text-uppercase">
                      Account Nickname
                    </h4>
                    <p class="text-small">Account ID: RA0W345</p>
                  </div>
                </a>
              </li>
              <li ngbDropdownItem>
                <a href="##" class="account-change-link">
                  <span class="text-uppercase account_badge">an</span>
                  <div class="account_details">
                    <h4 class="account_name_list text-uppercase">
                      Account Nickname
                    </h4>
                    <p class="text-small">Account ID: RA0W345</p>
                  </div>
                </a>
              </li>
              <li ngbDropdownItem>
                <a href="##" class="account-change-link">
                  <span class="text-uppercase account_badge">an</span>
                  <div class="account_details">
                    <h4 class="account_name_list text-uppercase">
                      Account Nickname
                    </h4>
                    <p class="text-small">Account ID: RA0W345</p>
                  </div>
                </a>
              </li>
            </ul>
          </div> -->
        </div>
        <div class="ai-side-nav">
          <ul>
            <li>
              <div class="sidebar-nav-link" routerLinkActive="sidebar-nav-link-active"
                [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/" class="">
                  <i class="fa-solid fa-briefcase"></i>
                  <p class="text_title">Portfolio</p>
                </a>
              </div>
              <div class="sidebar-nav-link" routerLinkActive=" sidebar-nav-link-active ">
                <a routerLink="/reporting" class="">
                  <i class="fa-solid fa-chart-simple"></i>
                  <p class="text_title">Reporting</p>
                </a>
              </div>
            </li>
            <!-- <li>
              <div class="sidebar-nav-link" routerLinkActive="sidebar-nav-link-active">
                <a routerLink="/invest" class="">
                  <i class="fa-solid fa-percent"></i>
                  <p class="text_title">Invest</p>
                </a>
              </div>
            </li> -->
          </ul>
        </div>
      </div>
      <div class="sidenav-button-footer">
        <div class="fooetr-inner-wrap">
          <a href="javascript: void(0);" class="footer_btn btn_hover_effect text-center text-uppercase" (click)="open(modal.Support, {
            ariaLabelledBy: 'modal-basic-title'
          })">
            <span class="title_text">Support</span>
            <span class="cap_text">S</span>
          </a>
          <a href="javascript: void(0);" class="footer_btn btn_hover_effect text-center text-uppercase" (click)="open(modal.AboutUs, {
            ariaLabelledBy: 'modal-basic-title'
          })">
            <span class="title_text">About us</span>
            <span class="cap_text">A</span>
          </a>
        </div>
        <div class="copyright_title">
          <p>© <span>Copyright Hawksford. All rights reserved.</span></p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-ng-basic-modal #modal></app-ng-basic-modal>