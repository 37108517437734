<div class="main_wrap">
  <div class="page_heading">
    <h2 class="text-uppercase page_title">REPORTING</h2>
  </div>

  <div class="tab">
    <ul class="main_tab">
      <li (click)="activeBtn(1)" [class]="CurrentTab === 1 ? 'active' : ''">Old Mutual US Dollar Money Market Fund</li>
      <li (click)="activeBtn(2)" [class]="CurrentTab === 2 ? 'active' : ''">Old Mutual US Dollar Duration Fund</li>
    </ul>
  </div>

  <div class="content">
    <div class="report_card_warp">
      <div class="card_inner">
        <div class="card_option">
          <div class="card_title">
            <img src="../../assets/images/unity-reporting-portfolio-all.svg" alt="" />
            <h5 class="text-uppercase">Valuation Gains</h5>
          </div>
          <div class="card_body">
            <p>
              <!-- Lorem Ipsum is simply dummy text of the printing and typesetting industry. -->
            </p>
          </div>
          <div class="card_footer">
            <button class="btn_hover_effect text-uppercase text-center open_btn" (click)="onClick(1)"
              [disabled]="isLoading[1]"> {{
              isLoading[1] ?
              "Loading..." : "Open" }}</button>
          </div>
        </div>
      </div>
      <div class="card_inner">
        <div class="card_option">
          <div class="card_title">
            <img src="../../assets/images/unity-reporting-portfolio-single.svg" alt="" />
            <h5 class="text-uppercase">Yield Report</h5>
          </div>
          <div class="card_body">
            <p>
              <!-- Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt. -->
            </p>
          </div>
          <div class="card_footer">
            <button class="btn_hover_effect text-uppercase text-center open_btn" (click)="onClick(2)"
              [disabled]="isLoading[2]">{{ isLoading[2] ?
              "Loading..." : "Open" }}</button>
          </div>
        </div>
      </div>
      <div class="card_inner">
        <div class="card_option">
          <div class="card_title">
            <img src="../../assets/images/unity-reporting-portfolio-single.svg" alt="" />
            <h5 class="text-uppercase">FactSheet Report</h5>
          </div>
          <div class="card_body">
            <p>
              <!-- Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt. -->
            </p>
          </div>
          <div class="card_footer">
            <button class="btn_hover_effect text-uppercase text-center open_btn" (click)="onClick(3)"
              [disabled]="isLoading[3]">{{ isLoading[3] ?
              "Loading..." : "Open" }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- HOLDING FILTER SIDEBAR-->
  <!-- class for open sidebar "show" -->
</div>