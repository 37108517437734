<body>
  <!-- <app-alert></app-alert> -->
  <div class="login-section">
    <div class="login-wrap">
      <div class="login-left-side">
        <div class="inner_content_details text-center">
          <div class="logo_img">
            <!-- <img src="../../assets/images/unity-logo-login-white.svg" alt="" /> -->
            <img src="../../assets/images/hawksford_logo.svg" />
          </div>
          <h2 class="title text-uppercase">Welcome to HAWKSFORD</h2>
        </div>
        <ul class="terms_privacy_footer">
          <li><a href="">Terms of use</a></li>
          <li><a href="">Privacy policy</a></li>
        </ul>
      </div>
      <div class="login-right-side">
        <div class="inner_form">
          <div class="title_box">
            <h1 class="title text-uppercase">Login</h1>
          </div>
          <app-alert></app-alert>

          <!-- LOGIN SECTION -->
          <section *ngIf="screen === 'login'">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="input_form">
              <div class="input_field_wrap form-group">
                <label for="email" class="input_label">email: </label>
                <input type="text" id="emial_field" formControlName="email"
                  [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                  <div *ngIf="f.email.errors.required">Email is required</div>
                  <div *ngIf="f.email.errors.pattern">
                    Please enter a valid email address.
                  </div>
                </div>
              </div>
              <div class="input_field_wrap">
                <label for="password_field" class="input_label">Password:
                </label>
                <input type="password" id="password_field" formControlName="password"
                  [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                  <div *ngIf="f.password.errors.required || f.password.errors.whitespace">
                    Password is required.
                  </div>
                </div>
                <div></div>
              </div>
              <!-- <div *ngIf="incorrect" style="
                  color: #dc3545;
                  font-size: 80%;
                  margin-top: 0.25rem;
                  width: 100%;
                ">
                Incorrect email or Password
              </div> -->
              <div class="form_chekbox">
                <label for="form_chekbox" class="main_label">
                  <img src="../../assets/images/icons8-check.svg" alt="__" />
                  <input type="checkbox" id="form_chekbox" class="chekbox_input mr-1" />
                </label>
                <label for="form_chekbox">Remember me</label>
              </div>
              <div class="btn-wraper form-group">
                <button [disabled]="isresendloading" class="border-0 text-uppercase login_btn form_btn">
                  <span *ngIf="isresendloading" class="spinner-border spinner-border-sm mr-1"></span>
                  <!-- Send OTP -->
                  {{ isresendloading ? "Loading..." : "Login" }}
                </button>
                <a routerLink="/forget" class="text-uppercase forgot_password_btn form_btn">forgot password</a>
              </div>
            </form>
          </section>

          <!-- OTP SECTION -->
          <section *ngIf="screen === 'OTP'">
            <form [formGroup]="OTPForm" (ngSubmit)="onSubmit()" class="input_form">
              <div class="input_field_wrap form-group">
                <label for="otp" class="input_label">OTP: </label>
                <input type="text" id="otp" formControlName="otp" placeholder="Enter 4 digit OTP"
                  [ngClass]="{ 'is-invalid': submitted && o.otp.errors }" maxlength="4" (keypress)="numericOnly($event)"
                  (keyup.enter)="handleEnterKey()" />
                <p class="timer">{{ showtimer ? 'Resend Otp In ' + minutes + ' : ' + (resendTimer % 60) : "" }}</p>
                <!-- <div *ngIf="submitted && o.otp.errors" class="invalid-feedback">
                  <div *ngIf="o.otp.errors.required">OTP is required.</div>
                  <div *ngIf="o.otp.errors.minlength || o.otp.errors.maxlength">
                    OTP should be exactly 4 digits long.
                  </div>
                  <div *ngIf="o.otp.errors.pattern">
                    This field should contain digits.
                  </div>
                </div> -->
              </div>
              <div class="btn-wraper form-group">
                <div>
                  <button type="button" [disabled]="showtimer" (click)="handleResendOTP()"
                    class="border-0 text-uppercase login_btn form_btn mx-1">
                    <span *ngIf="isresendloading" class="spinner-border spinner-border-sm mr-1"></span>
                    <!-- Resend OTP -->
                    {{ isresendloading ? "Loading..." : "Resend OTP" }}
                  </button>
                  <!-- <button [disabled]="isloading" class="border-0 text-uppercase login_btn form_btn mx-1">
                    <span *ngIf="isloading" class="spinner-border spinner-border-sm mr-1"></span>
                    {{ isloading ? "Loading..." : "Confirm" }}
                  </button> -->

                  <button [disabled]="isloading || OTPForm.invalid"
                    class="border-0 text-uppercase login_btn form_btn mx-1">
                    <span *ngIf="isloading" class="spinner-border spinner-border-sm mr-1"></span>
                    <!-- Confirm -->
                    {{ isloading ? "Loading..." : "Confirm" }}
                  </button>

                </div>
              </div>
            </form>
          </section>
        </div>
        <div class="right-side_footer">
          <div class="inner_box">
            <p>
              Still without an account?
              <a routerLink="/register"> Create one.</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>