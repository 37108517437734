import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AlertService } from '../_services/alert.service';
import { AuthenticationService } from '../_services/authentication.service';

@Component({
  selector: 'app-register',
  templateUrl: 'register.component.html',
  styleUrls: ['register.component.css'],
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  OTPForm: FormGroup;
  isloading = false;
  isresendloading = false;
  submitted = false;
  screen = 'register';
  userdata: any;
  resendTimer: number = 120;
  minutes: number = 0;
  showtimer = false

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      firstName: ['', [Validators.required, this.noWhitespaceValidator]],
      lastName: ['', [Validators.required, this.noWhitespaceValidator]],
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}'),
        ]),
      ],
      password: ['', [Validators.required, this.noWhitespaceValidator]],
    });

    this.OTPForm = this.formBuilder.group({
      otp: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(4),
          Validators.pattern('[0-9]+'),
        ]),
      ],
    });
  }

  noWhitespaceValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const isWhitespace = (control.value || '').trim().length === 0;
    return isWhitespace ? { 'whitespace': true } : null;
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;
  }

  get o() {
    return this.OTPForm.controls;
  }

  sendOTP() {
    this.isresendloading = true;
    let data = {
      "firstName": this.userdata.firstName.trim(),
      "lastName": this.userdata.lastName.trim(),
      "email": this.userdata.email.trim()
    }
    this.authenticationService.SendEmailConfirmationCode(data)
      .pipe(first())
      .subscribe(
        data => {
          this.isresendloading = false;
          this.screen = 'OTP';

          this.alertService.success('Verification code was successfully sent to your email.', true);
          this.registerForm.reset();
          Object.keys(this.registerForm.controls).forEach((key) => {
            this.registerForm.controls[key].setErrors(null);
            this.registerForm.controls[key].setValue('');
          });
        },
        error => {
          this.alertService.error(error, true);
          this.isresendloading = false;
        });
  }

  resetResendTimer() {
    this.resendTimer = 120;
    this.showtimer = true

    const timerInterval = setInterval(() => {
      this.resendTimer--;

      this.minutes = Math.floor(this.resendTimer / 60);

      if (this.resendTimer <= 0) {
        this.showtimer = false
        clearInterval(timerInterval);
      }
    }, 1000);
  }

  handleResendOTP() {
    this.OTPForm.reset();
    this.alertService.clear();
    this.resetResendTimer()
    this.sendOTP()
  }

  numericOnly(event): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  handleEnterKey() {
    // Programmatically click the Confirm button
    if (!this.isloading && !this.OTPForm.invalid) {
      this.onSubmit();
    }
  }

  onSubmit() {
    this.alertService.clear();
    this.submitted = true;
    // stop here if form is invalid
    if (
      (this.registerForm.invalid && this.screen != 'OTP') ||
      (this.OTPForm.invalid && this.screen != 'register')
    ) {
      return;
    }

    if (this.screen == 'OTP') {
      this.isloading = true;
      this.submitted = false;
      let data = {
        "firstName": this.userdata.firstName.trim(),
        "lastName": this.userdata.lastName.trim(),
        "email": this.userdata.email.trim(),
        "password": this.userdata.password.trim(),
        "code": this.OTPForm.value.otp.trim()
      }
      this.authenticationService.register(data)
        .pipe(first())
        .subscribe(
          data => {
            this.alertService.success('Registration successful', true);
            this.isloading = false;
            this.router.navigate(['/']);

            // RESET FORM VALUE AND ERRORs.
            this.OTPForm.reset();
            Object.keys(this.OTPForm.controls).forEach((key) => {
              this.OTPForm.controls[key].setErrors(null);
              this.OTPForm.controls[key].setValue('');
            });
          },
          error => {
            this.alertService.error(error, true);
            this.isloading = false;
          });

    } else {
      this.submitted = false;
      this.userdata = this.registerForm.value
      this.sendOTP()
    }
  }
}
