/*import { Component } from '@angular/core';
import Chart from 'chart.js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'AngularGriff';

  constructor() { }

  ngOnInit() {
    */ /*var textytext = document.getElementById("texttoChange");
var navData: Iterable<any>[] = NavData;
console.log(navData);
var ctx = document.getElementById("myChart");
var myChart = new Chart(ctx, {
type: 'bar',
data: {
labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
datasets: [{
label: '# of Votes',
data: [12, 19, 3, 5, 2, 3],
backgroundColor: [
'rgba(255, 99, 132, 0.2)',
'rgba(54, 162, 235, 0.2)',
'rgba(255, 206, 86, 0.2)',
'rgba(75, 192, 192, 0.2)',
'rgba(153, 102, 255, 0.2)',
'rgba(255, 159, 64, 0.2)'
],
borderColor: [
'rgba(255, 99, 132, 1)',
'rgba(54, 162, 235, 1)',
'rgba(255, 206, 86, 1)',
'rgba(75, 192, 192, 1)',
'rgba(153, 102, 255, 1)',
'rgba(255, 159, 64, 1)'
],
borderWidth: 1
}]
},
options: {
scales: {
yAxes: [{
ticks: {
beginAtZero: true
}
}]
}
}
});*/ /*

}
}
*/
/*import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  template: `
    <app-header></app-header>

    <router-outlet></router-outlet>

    <app-footer></app-footer>
  `,
  styleUrls: ['/node_modules/bulma/css/bulma.css']
})
export class AppComponent {
  title = 'AngularGriff';
}*/
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { User } from './_models/user';
import { AuthenticationService } from './_services/authentication.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgZone } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['../../node_modules/bulma/css/bulma.css', './app.component.css'],
})
export class AppComponent {
  title = 'AngularGriff';
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;

  /* Common Popup */
  @ViewChild('childModal', { static: false })
  public childModal: TemplateRef<any>;

  currentUser: User;

  open(content, optionSet?) {
    console.log('button clicked');
    this.modalService.open(content, {
      size: 'lg',
      scrollable: true,
    });
  }

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private idle: Idle,
    private keepalive: Keepalive,
    private modalService: NgbModal,
    private zone: NgZone,
  ) {
    this.authenticationService.currentUser.subscribe((x) => (this.currentUser = x));

    idle.setIdle(6000);
    // sets a timeout period of 14min. after 15min of inactivity, the user will be considered timed out.
    idle.setTimeout(60);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      console.log(this.idleState);
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      console.log('<<<<<<<<<', this.idleState);
      this.modalService.dismissAll();

      if (this.authenticationService.currentUserValue) {
        this.authenticationService.logout();

        // Ensure that the navigation to login is executed within the Angular zone
        this.zone.run(() => {
          this.router.navigate(['/login']);
        });
      }
    });


    idle.onIdleStart.subscribe(() => {
      this.idleState = "You've gone idle!";
      console.log(this.idleState);
      if (this.authenticationService.currentUserValue) {
        this.open(this.childModal);
      }
      // this.open(this.childModal);
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
      console.log(this.idleState);
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

    if (this.authenticationService.loggedIn) {
      idle.watch();
      this.timedOut = false;
    } else {
      idle.stop();
    }
  }

  // TEMP FOR TESTING MODAL
  OnInit() {
    debugger;
    this.open(this.childModal);
  }

  hideChildModal(): void {
    this.modalService.dismissAll();
  }

  logout() {
    this.modalService.dismissAll();
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  stay() {
    this.modalService.dismissAll();
    this.reset();
  }
}
