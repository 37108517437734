<div class="main_wrap">
  <div class="container">
    <div class="page_heading title_flex_wrap">
      <h2 class="text-uppercase page_title">Investment market place</h2>
      <div class="sidebar_toggle"><i class="fa-solid fa-sliders"></i></div>
    </div>
    <div class="invest_card_wraper">
      <div class="invest_card">invest</div>
      <div class="invest_card">invest</div>
    </div>
  </div>
</div>

