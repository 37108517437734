import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../api.service';
import { NgBasicModalComponent } from '../_components/ng-basic-modal/ng-basic-modal.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: [
    './home.component.css',
    '../../../node_modules/bulma/css/bulma.css',
  ],
})

export class HomeComponent implements OnInit {
  filterForm: FormGroup;
  isloading = true;
  isAccountNameOpen = false;
  isFilter = false;
  isTermsCondition = false;
  isRedeemAccount = false;
  isSubmitted = false;
  isRedeemFormError = false;
  isSubscribeFormError = false;
  shares: string = '1000';
  accountName: string = '';
  checkboxValues: string[] = [];
  Data: any = [];
  Holdings: any = [];

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private ApiService: ApiService
  ) { }

  ngOnInit() {
    this.GetPortFolio()
    this.filterForm = this.formBuilder.group({
      instrumentName: [false],
      acquisitionDate: [false],
      numberOfShares: [false],
      currentMarketValue: [false],
      daysInFund: [false],
      estimatedReturnToDate: [false],
      blendedAnnualReturnToDate: [false],
      estimatedValueAtNextCall: [false],
      totalFund: [false],
    });
  }

  GetPortFolio() {
    this.ApiService.GetPortFolio().subscribe((data: any) => {
      this.isloading = false
      this.Data = data.data;
      this.Holdings = data.data.holdings
    });
  }

  // Add a function to update the array when a checkbox changes
  updateCheckboxArray(event: Event, value: string) {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      this.checkboxValues.push(value);
    } else {
      this.checkboxValues = this.checkboxValues.filter(
        (item) => item !== value
      );
    }
  }

  // convenience getter for easy access to form fields
  /* Filter get method */
  get ff() {
    return this.filterForm.controls;
  }

  @ViewChild('modal') child: NgBasicModalComponent;
  open(content, fundInstrument, fundType, optionSet?) {
    console.log('button clicked');
    this.child.popupOpen();
    this.child.txtFund = fundInstrument;
    this.child.fundType = fundType;

    const modalRef = this.modalService.open(content, optionSet);
  }

  // handleIsFilter(): void {
  //   this.isFilter = !this.isFilter;
  // }

  // Add a function to handle the form submission
  submitForm() {
    // Use this.checkboxValues to access the selected checkbox values
    console.log(this.checkboxValues, this.filterForm);
  }
}
