import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Http, ResponseContentType, RequestOptions, Headers } from '@angular/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { environment } from 'src/environments/environment';
import { formatDate } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { Body } from '@angular/http/src/body';

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  readonly APIUrl = environment.baseUrl + '/api'; //"http://localhost:5000";

  constructor(private http: HttpClient, private httpp: Http,
    private authenticationService: AuthenticationService) { }

  getDBProfit(): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/dashboard/profit', { headers });
  }
  getAd(): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/admin/us', { headers });
  }
  getHols(): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/admin/holidays', { headers });
  }
  getUserDetails(): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/admin/userdetails', { headers });
  }
  getActivity(): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/admin/activity', { headers });
  }
  getExps(): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/admin/expenses', { headers });
  }
  getReds(num): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/admin/' + num + 'reds', { headers });
  }
  getBondUpdates(num): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/admin/' + num + 'bonds', { headers });
  }

  RedeemRequest(number) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/Shareholder/RedeemRequest', number, { headers });
  }

  InvestRequest(number) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/Shareholder/InvestRequest', number, { headers });
  }

  updateUserDetails(details) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/admin/update/userdetails', details, { headers });
  }
  updateUserPassword(passwords) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/admin/update/userpassword', passwords, { headers });
  }
  deleteBond(bond) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/admin/delete/bond', bond, { headers });
  }
  deleteRed(redemp) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/admin/delete/red', redemp, { headers });
  }
  deleteShare(redemp) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/admin/delete/share', redemp, { headers });
  }
  deleteExp(expense) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/admin/delete/expense', expense, { headers });
  }
  addUser(user) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/admin/add/user', user, { headers });
  }
  deleteUser(user) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/admin/delete/user', JSON.stringify(user), { headers });
  }
  getNavData(contract): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/navdata/' + contract, { headers });
  }
  getNavDataLine(contract): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/navdata/line/' + contract, { headers });
  }
  getBondContracts(): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/navdata', { headers });
  }
  getExpBonds(): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/navdata/expiring', { headers });
  }
  getPerfBonds(): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/navdata/performing', { headers });
  }
  getDBbonds(): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/navdata/dbData', { headers });
  }
  getPLData(): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/profitLoss', { headers });
  }
  getShareholderNums(): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/shareholder', { headers });
  }
  getShareholder(num): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/shareholder/' + num, { headers });
  }
  getDBholdings(): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/shareholder/shHoldings', { headers });
  }
  getDBsh(num): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/shareholder/shSumData/' + num, { headers });
  }
  getExpenses(): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/expenses', { headers });
  }
  getCCExpenses(): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/expenses/classc', { headers });
  }
  getAudit(): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/Audit', { headers });
  }
  updateExpense(object) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/addExpense', object, { headers });
  }
  addExpense(object) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/addExpense/add', object, { headers });
  }
  addBond(body) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/addBond', body, { headers });
  }
  addShareholder(body) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/ShareHolder/addShareholder', body, { headers });
  }
  redemption(body) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/Redemptions', body, { headers });
  }
  execNAV(body) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/ExecuteNAV', body, { headers });
  }
  NAVbutton(body) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/NAVbutton', body, { headers });
  }
  /*downloadBSheet() {
  const currentUser = this.authenticationService.currentUserValue;
  const headers = new Headers({ 'Content-Type': 'application/json', 'token': currentUser.token });
  const options = new RequestOptions({ responseType: ResponseContentType.Blob });
  const date = { "update": formatDate(new Date(), 'yyyy-MM-dd', 'en') };
  const op = { headers,date, options };
  //return this.http.get<any>(this.APIUrl + '/balancesheet'*//*, { headers }*//*);
  //return this.httpp.get(this.APIUrl + '/pandlreport', options);
  return this.httpp.get(this.APIUrl + '/api/reporting/bonds', op);
}*/
  downloadBondReport(date) {
    const headers = new Headers({ 'sdate': date, 'Authorization': 'Bearer ' + this.authenticationService.currentUserValue.data.token });
    return this.httpp.get(this.APIUrl + '/reporting/bonds', { responseType: ResponseContentType.Blob, headers: headers });
  }
  downloadPandLReport(date) {
    const headers = new Headers({ 'fdate': date.fromDate, 'tdate': date.toDate, 'Authorization': 'Bearer ' + this.authenticationService.currentUserValue.data.token });
    return this.httpp.get(this.APIUrl + '/reporting/profitandloss', { responseType: ResponseContentType.Blob, headers: headers });
  }
  downloadYTDReport(date) {
    const headers = new Headers({ 'fdate': date.fromDate, 'tdate': date.toDate, 'Authorization': 'Bearer ' + this.authenticationService.currentUserValue.data.token });
    return this.httpp.get(this.APIUrl + '/reporting/profitandloss', { responseType: ResponseContentType.Blob, headers: headers });
  }
  downloadShareholders(date) {
    const headers = new Headers({ 'tdate': date, 'Authorization': 'Bearer ' + this.authenticationService.currentUserValue.data.token });
    return this.httpp.get(this.APIUrl + '/reporting/shareholders', { responseType: ResponseContentType.Blob, headers: headers });
  }
  downloadBalanceSheet(date) {
    const headers = new Headers({ 'sdate': date, 'Authorization': 'Bearer ' + this.authenticationService.currentUserValue.data.token });
    return this.httpp.get(this.APIUrl + '/reporting/balancesheet', { responseType: ResponseContentType.Blob, headers: headers });
  }

  /*downloadBonds() {
      const currentUser = this.authenticationService.currentUserValue;
      const headers = { 'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'*//*, 'token': currentUser.token*//* };
const options = new RequestOptions({ responseType: ResponseContentType.Blob });
return this.httpp.get(this.APIUrl + '/api/reporting/bonds', options);
}*/

  saveCategory(data) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/Category/Save', data, { headers });
  }

  // GetReportBlob(reportId: any) {
  //   // const headers = { 'Content-Type': 'application/json' };
  //   const httpOptions = {
  //     responseType: 'blob' as 'json'
  //   };

  //   return this.http.get<any>(`${this.APIUrl}/Reporting/GetReportBlob/${reportId}`, httpOptions);
  // }

  GetReportBlob(reportId: any, fundType: number): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(`${this.APIUrl}/Reporting/GetReportBlob/${reportId}/${fundType}`,
      { observe: 'response', responseType: 'blob' as 'json' });
  }

  GetPortFolio() {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/Shareholder/GetPortFolio', { headers });
  }

}
