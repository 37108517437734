import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgBasicModalComponent } from '../_components/ng-basic-modal/ng-basic-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  isDropDown: boolean = false;
  @Input() isSideBar: boolean = false;
  @Input() getUserData: any
  @Output() isSideBarToggle: EventEmitter<boolean> = new EventEmitter();

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }

  handleDropDown() {
    this.isDropDown = !this.isDropDown;
  }

  handleSideBar() {
    this.isSideBarToggle.emit(!this.isSideBar);
    this.isSideBar = !this.isSideBar;
  }

  @ViewChild('modal') child: NgBasicModalComponent;
  open(content, optionSet?) {
    this.modalService.open(content, optionSet);
  }
}
