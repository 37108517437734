import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import { AuthenticationService } from '../_services/authentication.service';
import { first } from 'rxjs/operators';
import { AlertService } from '../_services/alert.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})

export class ProfileComponent implements OnInit {
  isLoader = true;
  UpdateForm: FormGroup;
  profileUrl: string = '';
  submitted: boolean = false;
  isLoading: boolean = false;
  file: File = null;

  constructor(
    private formBuilder: FormBuilder,
    private ApiService: ApiService,
    private authenticationService: AuthenticationService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    // this.profileUrl !== '' ? 'url(' + console.log(this.profileUrl) + ')' : console.log('url(../../assets/images/default-user.png)')
    console.log(this.profileUrl)
    this.userDataget()
    this.UpdateForm = this.formBuilder.group({
      profileUrl: [''],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: [''],
      sequentialNumber: [''],
      accountNumber: [''],
    });
  }

  get u() {
    return this.UpdateForm.controls;
  }

  userDataget() {
    this.authenticationService.GetUserDetail().subscribe((data: any) => {
      this.isLoader = false
      let getUserData = data.data
      this.profileUrl = getUserData.profileUrl
      this.UpdateForm.controls['firstName'].setValue(getUserData.firstName);
      this.UpdateForm.controls['lastName'].setValue(getUserData.lastName);
      this.UpdateForm.controls['email'].setValue(getUserData.email);
      this.UpdateForm.controls['sequentialNumber'].setValue(getUserData.sequentialNumber);
      this.UpdateForm.controls['accountNumber'].setValue(getUserData.accountNumber);
    }, error => {
      this.isLoader = false
      console.log(error)
    });
  }

  onProfilePictureChanged(event: any): void {
    const files = event.target.files;
    if (files && files.length > 0) {
      this.file = files[0];
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.profileUrl = e.target.result;
      };
      reader.readAsDataURL(this.file);
    }
  }

  onSubmit() {
    this.alertService.clear();
    this.submitted = true;
    if (this.UpdateForm.invalid) {
      return;
    }
    this.isLoading = true;
    let data = {
      "firstName": this.UpdateForm.value.firstName,
      "lastName": this.UpdateForm.value.lastName,
      "file": this.file
    }
    this.authenticationService.updateProfile(data)
      .pipe(first())
      .subscribe(
        data => {
          this.alertService.success("Profile Successfully Updated !", true);
          // Swal.fire({
          //   position: "top-end",
          //   icon: "success",
          //   title: "Profile Successfully Updated !",
          //   showConfirmButton: false,
          //   timer: 1500
          // });
          this.isLoading = false;
          this.submitted = false;
        },
        error => {
          console.log(error)
          this.submitted = false
          this.isLoading = false;
        });
  }

}
