import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { AlertService } from 'src/app/_services/alert.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-ng-basic-modal',
  templateUrl: './ng-basic-modal.component.html',
  styleUrls: ['./ng-basic-modal.component.css'],
})
export class NgBasicModalComponent {
  /* Update Account Name Modal */
  // @ViewChild('accountNameModal', { static: false })
  // public accountNameModal: TemplateRef<any>;

  @Input() fundTypeInput;

  /* changepassword popup */
  @ViewChild('changePassword', { static: false, })
  public changePassword: TemplateRef<any>;

  /* Redeem Modals */
  @ViewChild('redeemModal', { static: false })
  public redeemModal: TemplateRef<any>;

  @ViewChild('finalReview', { static: false })
  public finalReview: TemplateRef<any>;

  /* Subscribe modals */
  @ViewChild('subscribeModal', { static: false })
  public subscribeModal: TemplateRef<any>;

  @ViewChild('review', { static: false })
  public review: TemplateRef<any>;

  @ViewChild('fundingDetails', { static: false })
  public fundingDetails: TemplateRef<any>;

  /* Common Popup */
  @ViewChild('finalConfirmation', { static: false })
  public finalConfirmation: TemplateRef<any>;

  @ViewChild('finalTermsAndCondition', { static: false })
  public finalTermsAndCondition: TemplateRef<any>;

  @ViewChild('AboutUs', { static: false })
  public AboutUs: TemplateRef<any>;

  @ViewChild('Support', { static: false })
  public Support: TemplateRef<any>;

  redeemForm: FormGroup;
  subscribeForm: FormGroup;
  AccountNameForm: FormGroup;
  changePassForm: FormGroup;
  isInvest: boolean = false
  isAccountNameOpen: boolean = false;
  isFilter: boolean = false;
  isTermsCondition: boolean = false;
  isRedeemAccount: boolean = false;
  isSubmitted: boolean = false;
  isSubmittedPass: boolean = false;
  isRedeemFormError: boolean = false;
  isSubscribeFormError: boolean = false;
  isRedeem: boolean = false;
  isLoading = false
  redeemIsLoading: boolean = false
  investIsLoading: boolean = false
  txtFund: string;
  fundType: number;

  // shares: string = '1000';
  accountName: string = '';
  checkboxValues: string[] = [];

  // various sets of options configured here and passed by template click event
  optionsControlsOnly = {
    keyboard: false,
    backdrop: 'static',
    ariaLabelledBy: 'modal-dismissable-controls-only',
  };

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private ApiService: ApiService
  ) { }

  ngOnInit(): void {
    this.redeemForm = this.formBuilder.group({
      shares: ['', Validators.required],
    });

    this.subscribeForm = this.formBuilder.group({
      shares: ['', Validators.required],
      // amount: ['100000.00', Validators.required],
    });

    this.AccountNameForm = this.formBuilder.group({
      name: [''],
    });

    this.changePassForm = this.formBuilder.group({
      oldPassword: ['', [Validators.required, this.noWhitespaceValidator]],
      newPassword: ['', [Validators.required, this.noWhitespaceValidator]],
      confirmPassword: ['', [Validators.required, this.noWhitespaceValidator]],
    }, {
      validator: this.MustMatch('newPassword', 'confirmPassword')
    });
  }

  noWhitespaceValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const isWhitespace = (control.value || '').trim().length === 0;
    return isWhitespace ? { 'whitespace': true } : null;
  }

  numericOnly(event): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }


  open(content, optionSet?) {
    console.log('button clicked');
    this.modalService.open(content, {
      size: 'md',
      scrollable: true,
    });
  }

  closeicon() {
    this.isSubmittedPass = false
    this.changePassForm.reset()
    this.modalService.dismissAll('Close icon clicked')
  }

  // convenience getter for easy access to form fields
  /* redeemForm get method */
  get f() {
    return this.redeemForm.controls;
  }

  get c() {
    return this.changePassForm.controls;
  }

  /* subscriptionForm get method */
  get s() {
    return this.subscribeForm.controls;
  }

  /* AccountNameForm get method */
  get a() {
    return this.AccountNameForm.controls;
  }

  handleChangePassword() {
    this.alertService.clear();
    this.isSubmittedPass = true
    if (this.changePassForm.invalid) {
      return;
    }
    this.isLoading = true

    let data = {
      oldPassword: this.changePassForm.value.oldPassword.trim(),
      newPassword: this.changePassForm.value.newPassword.trim()
    }

    this.authenticationService.ChangePassword(data)
      .pipe(first())
      .subscribe(
        (data: any) => {
          this.isSubmittedPass = false;
          this.isLoading = false

          // RESET FORM VALUE AND ERRORs.
          this.changePassForm.reset();
          this.modalService.dismissAll();
        },
        error => {
          this.isLoading = false
          this.alertService.error(error, true);
        });
  }

  handleLinkClick() {
    this.open(this.finalTermsAndCondition);
  }


  checkSubscriptionStatus() {
    this.isSubmittedPass = false
    this.changePassForm.reset()
  }

  popupOpen() {
    this.isSubmitted = false
    this.subscribeForm.reset()
    this.redeemForm.reset()
  }

  // // Method to update the 'amount' based on 'shares'
  updateAmount() {
    // Get the shares value from the form
    const sharesValue = parseInt(this.redeemForm.get('shares').value);

    // Update the 'amount' form control with the new value
    this.redeemForm.patchValue({ amount: (sharesValue * 100).toFixed(2) });
  }

  handleRedeem(open: boolean): void {
    open ? this.open(this.redeemModal) : this.modalService.dismissAll();
  }


  handleSubscribe(open: boolean): void {
    open ? this.open(this.subscribeModal) : this.modalService.dismissAll();
  }

  handleFinalReview(open: boolean): void {
    this.isInvest = false;
    if (open) {
      this.isSubmitted = true;
      this.isRedeem = true;
      if (this.redeemForm.invalid) {
        this.isRedeemFormError = true;
        return;
      } else {
        this.isRedeemFormError = false;
        this.isSubmitted = false;
        this.modalService.dismissAll();
        this.open(this.finalReview);
      }
    } else {
      // this.isRedeem = true;
      this.isTermsCondition = false;
      this.isRedeemAccount = false;
      this.modalService.dismissAll();
      this.open(this.redeemModal);
    }
  }

  handleReview(open: boolean): void {
    this.isRedeem = false;
    if (open) {
      this.isSubmitted = true;
      this.isInvest = true;
      if (this.subscribeForm.invalid) {
        this.isSubscribeFormError = true;
        return;
      } else {
        this.modalService.dismissAll();
        this.open(this.review);
        this.isSubscribeFormError = false;
        this.isSubmitted = false;
      }
    } else {
      this.isTermsCondition = false;
      this.isRedeemAccount = false;
      this.modalService.dismissAll();
      this.open(this.subscribeModal);
    }
  }

  handleFinalConfirmation(open: boolean, type: string): void {
    if (open && type == 'redeem') {
      this.isSubmitted = true;

      if (this.isTermsCondition == false) {
        return;
      } else {
        this.redeemIsLoading = true
        let data = {
          "numberOfShares": this.redeemForm.value.shares,
          "fundType": this.fundType
        }
        this.ApiService.RedeemRequest(data).subscribe((data: any) => {
          if (data.data) {
            this.redeemIsLoading = false
            this.modalService.dismissAll();
            this.isInvest = false;
            this.isRedeem = true;
            this.open(this.finalConfirmation);
            this.isSubmitted = false;
            this.isTermsCondition = false;
            this.isRedeemAccount = false;
          }
        }, error => {
          this.redeemIsLoading = false
          console.log(error)
        });

      }
    } else if (open && type == 'subscription') {
      if (open) {
        this.isSubmitted = true;
        if (this.isTermsCondition == false) {
          return;
        } else {
          this.investIsLoading = true
          let data = {
            "numberOfShares": this.subscribeForm.value.shares,
            "fundType": this.fundType
          }
          this.ApiService.InvestRequest(data).subscribe((data: any) => {
            if (data.data) {
              this.investIsLoading = false
              this.modalService.dismissAll();
              this.isRedeem = false;
              this.isInvest = true;
              this.open(this.finalConfirmation);
            }
          }, error => {
            this.investIsLoading = false
            console.log(error)
          });

        }
      }
    } else {
      this.modalService.dismissAll();
    }
  }


}
