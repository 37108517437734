<!--- header --->
<div class="toolbar">
  <div class="tool_flex_wraper">
    <a href="javascript: void(0);" class="welcom_title">
      <h3 class="title">Welcome {{ getUserData.firstName }}</h3>
      <!-- <img src="../../assets/images/unity-logo-navigation-white.svg" alt="" /> -->
      <img src="../../assets/images/hawksford_logo.svg" />
    </a>

    <div class="top_profile_bar">
      <div>
        <div class="profile_inner_flex">
          <div ngbDropdown class="">
            <a id="dropdownBasic1" ngbDropdownToggle href="javascript:void(0);" aria-controls="basic-link-dropdown"
              class="user_details profile_menu_btn" (click)="handleDropDown()">
              <div class="user_img"
                [style.backgroundImage]="getUserData.profileUrl ? 'url(' + getUserData.profileUrl + ')' : 'url(../../assets/images/default-user.jpg)'">
              </div>
              <!-- <h6 class="title">Platform Pete</h6> -->
              <h6 class="title">{{ getUserData.firstName }} {{ getUserData.lastName }}</h6>

              <i *ngIf="!isDropDown" class="fa-solid fa-angle-down"></i>
              <i *ngIf="isDropDown" class="fa-solid fa-angle-up"></i>
            </a>
            <div class="profile_dropdown_menu dropdown-menu" id="basic-link-dropdown" ngbDropdownMenu
              aria-labelledby="dropdownBasic1">
              <ul>
                <li ngbDropdownItem>
                  <a (click)="open(modal.changePassword, {
                    ariaLabelledBy: 'modal-basic-title'
                  })" class="text-uppercase dropdown-item">Change Password</a>
                </li>
                <li ngbDropdownItem>
                  <a routerLink="/profile" class="text-uppercase dropdown-item">Profile</a>
                </li>
                <!-- <li ngbDropdownItem>
                  <a routerLink="/" class="text-uppercase dropdown-item">Banking Information</a>
                </li> -->
                <li ngbDropdownItem>
                  <a href="javascript: void(0);" (click)="logout()" class="text-uppercase dropdown-item">Logout</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile_nav_flex_wrap">
      <a href="javascript:void(0);" class="side_bar_toggle_btn " (click)="handleMobileSidebar()"><i
          class="fa-solid fa-bars-staggered"></i></a>
      <div ngbDropdown class="user_profile_drop_down">
        <a id="dropdownBasic1" ngbDropdownToggle href="javascript:void(0);" aria-controls="basic-link-dropdown"
          class="user_details profile_menu_btn" (click)="handleDropDown()">
          <i class="fa-regular fa-user"></i>
        </a>
        <div class="profile_dropdown_menu dropdown-menu" id="basic-link-dropdown" ngbDropdownMenu
          aria-labelledby="dropdownBasic1">
          <ul>
            <li ngbDropdownItem>
              <a (click)="
              open(modal.changePassword, {
                ariaLabelledBy: 'modal-basic-title'
              })
            " class="text-uppercase dropdown-item">Change Password</a>
            </li>
            <li ngbDropdownItem>
              <a routerLink="/profile" class="text-uppercase dropdown-item">Profile</a>
            </li>
            <!-- <li ngbDropdownItem>
              <a routerLink="/" class="text-uppercase dropdown-item">Banking Information</a>
            </li> -->
            <li ngbDropdownItem>
              <a href="javascript: void(0);" (click)="logout()" class="text-uppercase dropdown-item">Logout</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<app-ng-basic-modal #modal></app-ng-basic-modal>