import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';

import { User } from '../_models/user';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Idle } from '@ng-idle/core';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  baseUrl = environment.baseUrl + '/api';
  jwtHelper = new JwtHelperService();
  private updateSubject = new Subject<void>();
  update$ = this.updateSubject.asObservable();

  constructor(private http: HttpClient, private router: Router, private idle: Idle) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();

  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string) {
    const body = new HttpParams()
      .set('username', username).set('password', password);

    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.post<any>(this.baseUrl + '/login', { 'username': username, 'password': password }, options)
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        // if (user && user.token) {
        //     // store user details and jwt token in local storage to keep user logged in between page refreshes
        //     localStorage.setItem('currentUser', JSON.stringify(user));
        //     this.currentUserSubject.next(user);
        // }
        if (user.successful && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          sessionStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          this.idle.watch();
          user.username = username;
        }
        return user;
      }));
  }

  logout() {
    // remove user from local storage to log user ou
    this.http.post<any>(this.baseUrl + '/logout', { 'token': this.currentUserValue.data.token, 'expiry': this.currentUserValue.data.expiry }, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }).subscribe();
    sessionStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    this.router.navigate(['/login']);
    this.idle.stop();
  }

  loggedIn() {
    const token = JSON.parse(sessionStorage.getItem('currentUser'));
    if (token) {
      return !this.jwtHelper.isTokenExpired(token.token);
    }
    return false;
  }


  SendEmailConfirmationCode(data: any) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.post(`${this.baseUrl}/Account/SendEmailConfirmationCode`, data, options)
  }

  GetUserDetail(): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.baseUrl + '/User/GetUserDetail', { headers });
  }

  updateProfile(data) {
    const body = new FormData();
    body.append("File", data.file);
    body.append("firstName", data.firstName);
    body.append("lastName", data.lastName);
    return this.http.post(`${this.baseUrl}/User/UpdateUserProfile`, body).pipe(map(user => {
      this.updateSubject.next();
    }));
  }

  register(data: any) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post<any>(`${this.baseUrl}/Account`, data, options)
      .pipe(map(user => {
        if (user.isSuccessful && user.data.token) {
          sessionStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          this.idle.watch();
        }
        return user;
      }));
  }

  SignIn(data: any) {
    const userdata = {
      username: data.email.trim(),
      password: data.password.trim()
    }
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.post(`${this.baseUrl}/Account/SignIn`, userdata, options)
  }

  TwoWayAuthenticate(data: any) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post<any>(`${this.baseUrl}/Account/TwoWayAuthenticate`, data, options)
      .pipe(map(user => {
        if (user.isSuccessful && user.data.token) {
          sessionStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          this.idle.watch();
        }
        return user;
      }));
  }

  ForgotPassword(data: any) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post(`${this.baseUrl}/Account/ForgotPassword`, data, options)
  }


  VerifyResetPasswordCode(data: any) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post(`${this.baseUrl}/Account/VerifyResetPasswordCode`, data, options)
  }

  ResetPassword(data: any) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post(`${this.baseUrl}/Account/ResetPassword`, data, options)
  }

  ChangePassword(data: any) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post(`${this.baseUrl}/User/ChangePassword`, data, options)
  }

}
