import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import { AlertService } from '../_services/alert.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
})
export class ChangePasswordComponent implements OnInit {
  changePassForm: FormGroup;
  loading = false;
  submitted = false;
  incorrect = false;


  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.changePassForm = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    }, {
      validator: this.MustMatch('newPassword', 'confirmPassword')
    });
  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.changePassForm.controls;
  }
  onSubmit() {
    this.alertService.clear();
    this.submitted = true;
    this.incorrect = false;

    // stop here if form is invalid
    if (this.changePassForm.invalid) {
      return;
    }
    this.loading = true
    let data = {
      oldPassword: this.changePassForm.value.oldPassword,
      newPassword: this.changePassForm.value.newPassword
    }

    this.authenticationService.ChangePassword(data)
      .pipe(first())
      .subscribe(
        (data: any) => {
          console.log(data)
          this.loading = false
          this.submitted = false;
          this.router.navigate(['/']);
          // RESET FORM VALUE AND ERRORs.
          this.changePassForm.reset();
          // Object.keys(this.changePassForm.controls).forEach((key) => {
          //   this.changePassForm.controls[key].setErrors(null);
          //   this.changePassForm.controls[key].setValue('');
          // });

        },
        error => {
          this.loading = false
          this.alertService.error(error, true);
        });


  }
}
