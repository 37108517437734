<div class="main_wraper">
  <!--  "show" class for overlay visible -->
  <div
    [ngClass]="{
      'dark-overlay': true,
      show: isSideBar
    }"
  ></div>
  <app-sidebar
    (isSideBarToggle)="onDataReceived($event)"
    [isSideBar]="isSideBar"
    [getUserData]="getUserData"
  ></app-sidebar>

  <div
    [ngClass]="{
      'app-contetn-bar': true,
      'full-width': isSideBar
    }"
  >
    <app-header
      (isHeaderToggleSideBar)="onDataReceived($event)"
      [isSideBar]="isSideBar"
      [getUserData]="getUserData"
    ></app-header>
    <div class="content-sec">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<!-- <app-footer></app-footer> -->
